import { useZodGet } from '@/api';
import { TasksParam } from '@/api/params';
import { Tasks, TasksSchema } from '@/api/schemas';
import { useAuthContext } from '@/contexts/AuthContext';
import { useTasksCounter } from '@/hooks/global/useTasksCounter';
import { usePaginator } from '@/hooks/utils/usePaginator';
import { useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  tasksParam: TasksParam;
  updateTasksParamObject: (object: Partial<TasksParam>) => void;
  isTaskTray?: boolean;
  hasAutoReload?: boolean;
};

/**
 * JSDoc
 * @see Tasksを扱うカスタムフック
 * @see TaskList(gallery)とTaskTrayの両方で使用
 */
export const useTasks = ({
  tasksParam,
  updateTasksParamObject,
  isTaskTray,
  hasAutoReload,
}: Props) => {
  const [apiResponse, setApiResponse] = useState<Tasks | undefined>(undefined);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const [isAutoReload, setIsAutoReload] = useState(false);
  const { user } = useAuthContext();
  const { updateTaskTrayCounter, updateTaskListCounter } = useTasksCounter();

  /* TaskTrayの場合はuserIdの追加、limitを10に変更 */
  const modifiedTasksParam = useMemo(() => {
    if (isTaskTray) {
      return { ...tasksParam, userId: user?.id, limit: 10 };
    }

    return tasksParam;
  }, [isTaskTray, tasksParam, user?.id]);

  const {
    data: taskData,
    isLoading,
    isFetching,
  } = useZodGet('gen/tasks', TasksSchema, modifiedTasksParam);

  const { count, offset, page, handleChangePage } = usePaginator({
    pageInfo: apiResponse?.pageInfo,
  });

  useEffect(() => {
    if (!taskData) return;
    if (isLoading || isFetching) return;
    setApiResponse(taskData);
    setIsAutoReload(false);
    // eslint-disable-next-line no-unused-expressions
    isTaskTray
      ? updateTaskTrayCounter(taskData)
      : updateTaskListCounter(taskData);
  }, [
    taskData,
    isLoading,
    isFetching,
    isTaskTray,
    updateTaskTrayCounter,
    updateTaskListCounter,
  ]);

  /* ページネーション選択時にparamのoffsetを更新して発火 */
  useEffect(() => {
    updateTasksParamObject({
      offset,
    });
  }, [updateTasksParamObject, offset]);

  /* 10秒ごとにデータを取得する 2ページ目以降は更新しない */
  useEffect(() => {
    if (!hasAutoReload) return () => {};
    if (page > 1) return () => {};
    const timeoutId = setTimeout(() => {
      setIsAutoReload(true);
      updateTasksParamObject({
        timer: Date.now(),
      });
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [apiResponse, updateTasksParamObject, page, hasAutoReload]);

  /* 特定のtasksParamが更新された場合はスクロール状態をリセット */
  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [
    tasksParam.limit,
    tasksParam.offset,
    tasksParam.createdAtAfterUnix,
    tasksParam.createdAtBeforeUnix,
    tasksParam.userId,
  ]);

  return {
    apiResponse,
    isLoading,
    isFetching,
    count,
    page,
    offset,
    handleChangePage,
    scrollBoxRef,
    isAutoReload,
  };
};
