import {
  StaticButton,
  StaticButtonProps,
} from '@/components/atoms/StaticButton';
import { memo } from 'react';
import * as vanilla from './button.css';

export const StyledContactTallyButton = memo(
  ({ children }: StaticButtonProps) => {
    return (
      <button
        type="button"
        className={vanilla.styledContactTallyButton}
        data-tally-open="nPzzQ0"
      >
        {children}
      </button>
    );
  },
);

export const StyledHomeBorderButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledHomeBorderButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledAdminDetailBorderButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledAdminDetailBorderButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledAdminAddUserButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledAdminAddUserButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledCalendarSearchButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledCalendarSearchButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledCalendarClearBorderButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledCalendarClearBorderButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledUploadFileSelectButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledUploadFileSelectButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionPreviewButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionPreviewButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionClearButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionClearButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionGenerateButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionGenerateButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionNextButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionNextButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledNumberPlusMinusButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledNumberPlusMinusButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionDownloadButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionDownloadButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionRepeatButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionRepeatButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledFunctionCompleteButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledFunctionCompleteButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledDownloaderCheckAllButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDownloaderCheckAllButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDownloaderUnCheckAllButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDownloaderUnCheckAllButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledCancelRepeatGenButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledCancelRepeatGenButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StylePopperColorCancelButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.stylePopperColorCancelButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StylePopperColorOkButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.stylePopperColorOkButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StylePopperUserLogoutButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.stylePopperUserLogoutButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StylePopperTaskTrayGalleryButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.stylePopperTaskTrayGalleryButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogAddUserCancelButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogAddUserCancelButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogAddUserButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledDialogAddUserButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogDeleteUserBorderButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogDeleteUserBorderButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogDeleteUserButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledDialogDeleteUserButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogSendInvitationButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogSendInvitationButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogConfirmAbortionButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogConfirmAbortionButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogErrorOkButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledDialogErrorOkButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogRepeatGenCancelButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogRepeatGenCancelButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogRepeatGenOkButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogRepeatGenOkButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogProgressBackgroundButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton
        className={vanilla.styledDialogProgressBackgroundButton}
        {...props}
      >
        {children}
      </StaticButton>
    );
  },
);

export const StyledDialogUploadEndButton = memo(
  ({ children, ...props }: StaticButtonProps) => {
    return (
      <StaticButton className={vanilla.styledDialogUploadEndButton} {...props}>
        {children}
      </StaticButton>
    );
  },
);
