import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useCallback } from 'react';

export const useBackgroundParam = () => {
  const { featureData } = useFeaturesContext({});

  const getBackgroundSingleColorParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      parameters: {
        color: featureData?.single?.param?.currentColor,
        with_shadow: featureData?.single?.param?.withShadow === 'on',
      },
      rough: false,
      generation_mode: 'generate_background_single_color',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.currentColor,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.withShadow,
  ]);

  const getBackgroundRefImageParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      parameters: {
        with_shadow: featureData?.single?.param?.withShadow === 'on',
        add_bokeh: featureData?.single?.param?.blurLevel,
      },
      rough: false,
      generation_mode: 'generate_background_by_reference_image',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
      ref_image: featureData?.refImage?.base64,
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.blurLevel,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.withShadow,
    featureData?.refImage?.base64,
  ]);

  const getBackgroundSinglePresetParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      parameters: {
        color: featureData?.single?.param?.currentColor,
        with_shadow: featureData?.single?.param?.withShadow === 'on',
        returnBgOnly: false,
        presetId: featureData?.single?.param?.presetId,
      },
      rough: false,
      isHidden: false,
      generation_mode: 'generate_background_by_preset',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.currentColor,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.presetId,
    featureData?.single?.param?.withShadow,
  ]);

  return {
    getBackgroundSingleColorParam,
    getBackgroundRefImageParam,
    getBackgroundSinglePresetParam,
  };
};
