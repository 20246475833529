import { Task } from '@/api/schemas';
import { Dialog } from '@/components/molecules/Dialog';
import { memo } from 'react';
import { BackgroundLoraContent } from './BackgroundLoraContent';
import { ColorTemperatureContent } from './ColorTemperatureContent';

type Props = {
  taskApiResponse: Task | undefined;
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  targetModeEn: string;
};

export const BatchDownloadDialog = memo(
  ({
    taskApiResponse,
    isOpenDialog,
    handleCloseDialog,
    targetModeEn,
  }: Props): JSX.Element => {
    console.log('batch download dialog');

    return (
      <>
        {taskApiResponse && (
          <Dialog
            isOpenDialog={isOpenDialog}
            onClose={handleCloseDialog}
            maxWidth="xl"
            dialogTitle=""
            hasCloseIcon
          >
            {targetModeEn === 'colorTemperature' ? (
              <ColorTemperatureContent taskApiResponse={taskApiResponse} />
            ) : (
              <BackgroundLoraContent taskApiResponse={taskApiResponse} />
            )}
          </Dialog>
        )}
      </>
    );
  },
);
