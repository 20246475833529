import { Tasks } from '@/api/schemas';
import { useDownloadButtonHandlers } from '@/features/Gallery/hooks/useDownloadButtonHandlers';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { DownloadDialog } from '@/features/components/modals/DownloadDialog';
import { TableBody, TableCell as Td } from '@mui/material';
import { memo } from 'react';
import { Date } from './Date';
import { DownloadButton } from './DownloadButton';
import { Status } from './Status';
import { StyledTdFile, StyledTdFixed, StyledTr } from './styled';

type Props = {
  apiResponse: Tasks;
};

export const Body = memo(({ apiResponse }: Props): JSX.Element => {
  const {
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
    handleCloseDownloadDialog,
    isOpenDownloadDialog,
    taskId,
  } = useDownloadButtonHandlers();

  return (
    <>
      <TableBody>
        {apiResponse.data.map((data) => (
          <StyledTr key={data.id} status={data.result.status}>
            <StyledTdFile>
              <TaskCellMode data={data} />
            </StyledTdFile>
            <StyledTdFixed>
              <TaskCellContent data={data} />
            </StyledTdFixed>
            <StyledTdFixed>
              <Status data={data} />
            </StyledTdFixed>
            <Td>
              <Date data={data} />
            </Td>
            <Td>
              <DownloadButton
                data={data}
                handleSubmitTargetDownloadStep={handleSubmitTargetDownloadStep}
                handleOpenDownloadDialog={handleOpenDownloadDialog}
              />
            </Td>
          </StyledTr>
        ))}
      </TableBody>
      <DownloadDialog
        taskId={taskId}
        isOpenDownloadDialog={isOpenDownloadDialog}
        handleCloseDownloadDialog={handleCloseDownloadDialog}
      />
    </>
  );
});
