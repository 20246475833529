import { EditSetNameDialog } from '@/features/components/modals/EditSetNameDialog';
import { useAccordionContext } from '@/features/components/molecules/AccordionWrapper/hooks/useAccordionContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { useDialog } from '@/hooks/local/useDialog';
import { memo } from 'react';
import { Body } from './Body';
import { Head } from './Head';

type SetTableProps = {
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
};

/**
 * @see バッチアップロードのセットの状態を表示する親コンポーネント
 * @see HEAD カラム
 * @see BODY データ
 * @see EditSetNameDialog セット名変更のダイアログ
 */
export const SetTable = memo(
  ({ setFunctionViewType, warningFunction }: SetTableProps): JSX.Element => {
    const { currentNumber, batchConstant } = useBatchUpload();
    const {
      isOpenDialog: isOpenSetNameDialog,
      handleOpenDialog: handleOpenSetNameDialog,
      handleCloseDialog: handleCloseSetNameDialog,
    } = useDialog();

    const {
      isDisabledAccordionAll,
      accordionAllButtonObject,
      handleClickAllAccordion,
    } = useAccordionContext({ isInitial: true, currentNumber });

    return (
      <>
        <Head
          isDisabledAccordionAll={isDisabledAccordionAll}
          batchConstant={batchConstant}
          accordionAllButtonObject={accordionAllButtonObject}
          handleClickAllAccordion={handleClickAllAccordion}
        />
        <Body
          handleOpenSetNameDialog={handleOpenSetNameDialog}
          setFunctionViewType={setFunctionViewType}
          warningFunction={warningFunction}
        />
        <EditSetNameDialog
          isOpenDialog={isOpenSetNameDialog}
          handleCloseDialog={handleCloseSetNameDialog}
        />
      </>
    );
  },
);
