/* eslint-disable import/newline-after-import */
import { memo } from 'react';
type Props = {
  color?: string;
};
const ColorTemperatureIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.334 6.667a4.667 4.667 0 1 1 9.333 0v11.115a7 7 0 1 1-9.333 0zm6.066 7h-2.8v6.124a3.5 3.5 0 1 0 2.8 0z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default ColorTemperatureIcon;
