/* eslint-disable import/newline-after-import */
import type { SVGProps } from 'react';
import { memo } from 'react';
const UserIcon = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      {...props}
    >
      <rect width={32} height={32} fill="#BDBDBD" rx={16} />
      <path
        fill="#fff"
        d="M16 16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4Z"
      />
    </svg>
  );
});
export default UserIcon;
