import { Box, BoxProps } from '@/components/atoms/Box';
import { styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledThumbnailContainer = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledThumbnailContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledThumbnailBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledThumbnailBox} {...props}>
      {children}
    </Box>
  );
});

const SvgCheckedIcon = `
<svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
<style type="text/css">
	.st0{fill:${encodeURIComponent('#ffffff')};}
	.st1{fill:${encodeURIComponent('#2196F3')};}
</style>
	<circle class="st0" cx="12" cy="12" r="10.5"/>
	<circle class="st0" cx="12" cy="12" r="11.8"/>
<path class="st1" d="M12,1.5C6.2,1.5,1.5,6.2,1.5,12c0,5.8,4.7,10.5,10.5,10.5c5.8,0,10.5-4.7,10.5-10.5C22.5,6.2,17.8,1.5,12,1.5z
	 M16.5,8.6l-4.9,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2l-2.9-4
	c-0.1-0.1,0-0.3,0.2-0.3h1.1c0.2,0,0.5,0.1,0.6,0.3l1.7,2.3l3.7-5.1c0.1-0.2,0.4-0.3,0.6-0.3h1.1C16.5,8.3,16.6,8.4,16.5,8.6z"/>
</svg>
`.replace(/\n/g, '');

const SvgDownloadedIcon = `
<svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
<style type="text/css">
	.st0{fill:${encodeURIComponent('#ffffff')};}
	.st1{fill:${encodeURIComponent('#cccccc')};}
</style>
	<circle class="st0" cx="12" cy="12" r="10.5"/>
	<circle class="st0" cx="12" cy="12" r="11.8"/>
<path class="st1" d="M12,1.5C6.2,1.5,1.5,6.2,1.5,12c0,5.8,4.7,10.5,10.5,10.5c5.8,0,10.5-4.7,10.5-10.5C22.5,6.2,17.8,1.5,12,1.5z
	 M16.5,8.6l-4.9,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2l-2.9-4
	c-0.1-0.1,0-0.3,0.2-0.3h1.1c0.2,0,0.5,0.1,0.6,0.3l1.7,2.3l3.7-5.1c0.1-0.2,0.4-0.3,0.6-0.3h1.1C16.5,8.3,16.6,8.4,16.5,8.6z"/>
</svg>
`.replace(/\n/g, '');

const SvgUnCheckedIcon =
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="white"/>
<circle cx="12" cy="12" r="9.5" stroke="black" stroke-opacity="0.12" stroke-width="2"/>
</svg>
`.replace(/\n/g, '');

export const StyledThumbnail = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'swiperIndex' &&
    prop !== 'isChecked' &&
    prop !== 'hasCheckIcon' &&
    prop !== 'isChecked' &&
    prop !== 'isDownloaded',
})<{
  index: number;
  swiperIndex: number;
  hasCheckIcon?: boolean;
  isChecked?: boolean;
  isDownloaded?: boolean;
}>(
  ({
    theme,
    index,
    swiperIndex,
    hasCheckIcon = false,
    isChecked,
    isDownloaded,
  }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '72px',
    height: '72px',
    '& img': {
      display: 'block',
      objectFit: 'contain',
    },
    borderRadius: '4px',
    outline:
      swiperIndex === index
        ? `2px solid ${'var(--color-primary-main)'}`
        : `1px solid ${'var(--color-gray-soft)'}`,
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: 'var(--color-gray-white)',
    '&::after':
      hasCheckIcon && isChecked
        ? {
            content: "''",
            position: 'absolute',
            width: '24px',
            height: '24px',
            marginLeft: 45,
            marginTop: 45,
            backgroundImage: `url('data:image/svg+xml;utf8, ${SvgCheckedIcon}')`,
          }
        : hasCheckIcon && isDownloaded
        ? {
            content: "''",
            position: 'absolute',
            width: '24px',
            height: '24px',
            marginLeft: 45,
            marginTop: 45,
            backgroundImage: `url('data:image/svg+xml;utf8, ${SvgDownloadedIcon}')`,
          }
        : hasCheckIcon && !isDownloaded && !isChecked
        ? {
            content: "''",
            position: 'absolute',
            width: '24px',
            height: '24px',
            marginLeft: 45,
            marginTop: 45,
            backgroundImage: `url('data:image/svg+xml;utf8, ${SvgUnCheckedIcon}')`,
          }
        : {},
  }),
);

export const StyledImage = memo(({ children, ...props }: ImageProps) => {
  return <Image className={vanilla.styledImage} {...props} />;
});
