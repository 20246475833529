import { StyledFlexAlignStart } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { GlobalViewer } from '@/features/components/organisms/GlobalViewer';
import { KeepMask } from '@/features/components/organisms/KeepMask';
import { StyledPreview } from '@/features/components/styled/preview';
import Image from 'next/image';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SwiperSlide } from 'swiper/react';
import { CircledTargetContent } from './CircledTargetContent';
import { StyledGlobalViewerPreviewBox, StyledPreviewContainer } from './styled';

type Props = {
  repeatBackgroundImage: string;
};
export const Preview = ({ repeatBackgroundImage }: Props) => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  return (
    <>
      <StyledFlexAlignStart>
        <StyledPreviewContainer>
          {!featureData?.single?.genStatus?.isGenerated && (
            <>
              <CircledTargetContent />
              <StyledPreview swiperIndex={0} maxIndex={1} width={660}>
                <SwiperSlide>
                  {featureData?.mainImage.combinedBase64 && (
                    <ImageSpinner src={featureData?.mainImage.combinedBase64}>
                      <Image
                        src={featureData?.mainImage.combinedBase64}
                        fill
                        style={{ objectFit: 'contain' }}
                        alt="pict"
                      />
                    </ImageSpinner>
                  )}
                </SwiperSlide>
              </StyledPreview>
            </>
          )}
          {featureData?.single?.genStatus?.isGenerated &&
            featureData?.single?.genResult.generatedImageResults.length > 0 && (
              <GlobalViewer
                imageResults={
                  featureData?.single?.genResult.generatedImageResults
                }
                repeatBackgroundImage={repeatBackgroundImage}
                label={
                  <StyledGlobalViewerPreviewBox>
                    プレビュー中
                  </StyledGlobalViewerPreviewBox>
                }
                hasAnimation
              />
            )}
        </StyledPreviewContainer>
        {activeFeatureName === 'item' && (
          <>
            <KeepMask />
          </>
        )}
      </StyledFlexAlignStart>
    </>
  );
};
