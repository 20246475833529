/* eslint-disable import/newline-after-import */
import { memo } from 'react';

type Props = {
  isOpenTaskTray: boolean;
};
const TrayIcon = memo(({ isOpenTaskTray }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={36} fill="none">
      <path
        fill={isOpenTaskTray ? 'var(--color-primary-main)' : '#999'}
        d="M27.708 4.875H7.292a2.925 2.925 0 0 0-2.917 2.917V18a2.925 2.925 0 0 0 2.917 2.917h20.416A2.925 2.925 0 0 0 30.625 18V7.792a2.925 2.925 0 0 0-2.917-2.917m0 8.75h-5.833c0 2.363-2.012 4.375-4.375 4.375s-4.375-2.012-4.375-4.375H7.292V7.792h20.416zm-5.833 10.208h8.75v4.375a2.925 2.925 0 0 1-2.917 2.917H7.292a2.925 2.925 0 0 1-2.917-2.917v-4.375h8.75a4.37 4.37 0 0 0 4.375 4.375 4.37 4.37 0 0 0 4.375-4.375"
      />
    </svg>
  );
});
export default TrayIcon;
