import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledBoxWidth10, StyledFlexGap10 } from '@/components/styled';
import { Divider } from '@mui/material';
import { memo } from 'react';
import { Completed } from './Completed';
import { Failed } from './Failed';
import { InProgress } from './InProgress';
import { InQueue } from './InQueue';

type Props = {
  data: Task;
};

export const Status = memo(({ data }: Props): JSX.Element => {
  return (
    <Box>
      <StyledFlexGap10>
        <Divider orientation="vertical" flexItem />
        <StyledBoxWidth10 />
        {data.result.status === 'COMPLETED' && <Completed data={data} />}
        {data.result.status === 'IN_PROGRESS' && <InProgress data={data} />}
        {data.result.status === 'IN_QUEUE' && <InQueue data={data} />}
        {(data.result.status === 'FAILED' ||
          data.result.status === 'TIMEOUT') && <Failed data={data} />}
      </StyledFlexGap10>
    </Box>
  );
});
