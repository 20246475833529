import { Box } from '@/components/atoms/Box';
import {
  StyledFlex,
  StyledFlexEnd,
  StyledFont16Bold,
  StyledFontNowrap,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { CircularProgress } from '@mui/material';
import { memo, useCallback } from 'react';
import { MdDownload } from 'react-icons/md';

type Props = {
  width?: string | number;
  label: string | JSX.Element;
  label2: string | JSX.Element;
  target: string;
  downloadingName: string;
  handleDownloadWithApi: (format: string) => Promise<void>;
  isDialog?: boolean;
};
/**
 * JSDoc
 * @see ダウンロードボタン
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const DownloadButton = memo(
  ({
    width = '100%',
    label,
    label2,
    target,
    downloadingName,
    handleDownloadWithApi,
    isDialog,
  }: Props): JSX.Element => {
    const { updateFeatureDataSingle } = useFeaturesContext({});

    const handleDownload = useCallback(
      (format: string) => {
        if (!isDialog) {
          updateFeatureDataSingle('param', { format });
        }
        void handleDownloadWithApi(format);
      },
      [handleDownloadWithApi, isDialog, updateFeatureDataSingle],
    );

    return (
      <StyledFlex
        width={width}
        sx={{
          width: 280,
          height: 56,
          backgroundColor: '#eee',
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            width: '80px',
            minWidth: '80px',
            paddingLeft: '16px',
          }}
        >
          <StyledFont16Bold>{label}</StyledFont16Bold>
        </Box>
        <Box>
          <StyledFontNowrap>{label2}</StyledFontNowrap>
        </Box>

        <StyledFlexEnd>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '56px',
              height: '56px',
              backgroundColor: '#eee',
              borderRadius: '4px',
              color: 'var(--color-primary-deep)',
              '&:hover': {
                backgroundColor: 'var(--color-primary-pale)',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              handleDownload(target);
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
              }}
            >
              {downloadingName === target ? (
                <CircularProgress style={{ width: 24, height: 24 }} />
              ) : (
                <MdDownload size={24} />
              )}
            </Box>
          </Box>
        </StyledFlexEnd>
      </StyledFlex>
    );
  },
);
