import { Preset } from '@/api/schemas';
import { StyledFlexGap10 } from '@/components/styled';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import Image from 'next/image';
import { memo } from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import {
  StyledRowBox,
  StyledRowDisplayNameBox,
  StyledRowTitleBox,
} from '../styled';

type Props = {
  preset: Preset;
  targetValue: string;
  setTargetValue: (value: string) => void;
};

export const RowData = memo(
  ({ preset, targetValue, setTargetValue }: Props): JSX.Element => {
    return (
      <StyledRowBox>
        <StyledRowTitleBox onClick={() => setTargetValue(preset.id)}>
          <StyledFlexGap10>
            {targetValue === preset.id ? (
              <MdRadioButtonChecked
                color="var(--color-primary-main)"
                size={24}
                data-testid="radio-checked-icon"
              />
            ) : (
              <MdRadioButtonUnchecked
                color="var(--color-gray-main)"
                size={24}
                data-testid="radio-unchecked-icon"
              />
            )}
            <StyledRowDisplayNameBox>
              {preset.displayName}
            </StyledRowDisplayNameBox>
          </StyledFlexGap10>
        </StyledRowTitleBox>
        {preset.examples.length > 0 && (
          <>
            {preset.examples[0].url && (
              <ImageSpinner
                src={preset.examples[0].url}
                mode="beater"
                hasBackground={false}
              >
                <Image
                  src={preset.examples[0].url}
                  width={104}
                  height={140}
                  style={{ objectFit: 'cover' }}
                  alt="サンプル1"
                />
              </ImageSpinner>
            )}
            {preset.examples[1].url && (
              <ImageSpinner
                src={preset.examples[1].url}
                mode="beater"
                hasBackground={false}
              >
                <Image
                  src={preset.examples[1].url}
                  width={104}
                  height={140}
                  style={{ objectFit: 'cover' }}
                  alt="サンプル1"
                />
              </ImageSpinner>
            )}
            {preset.examples[2].url && (
              <ImageSpinner
                src={preset.examples[2].url}
                mode="beater"
                hasBackground={false}
              >
                <Image
                  src={preset.examples[2].url}
                  width={104}
                  height={140}
                  style={{ objectFit: 'cover' }}
                  alt="サンプル1"
                />
              </ImageSpinner>
            )}
            {preset.examples[3].url && (
              <ImageSpinner
                src={preset.examples[3].url}
                mode="beater"
                hasBackground={false}
              >
                <Image
                  src={preset.examples[3].url}
                  width={104}
                  height={140}
                  style={{ objectFit: 'cover' }}
                  alt="サンプル1"
                />
              </ImageSpinner>
            )}
          </>
        )}
      </StyledRowBox>
    );
  },
);
