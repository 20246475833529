import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect } from 'react';

type Props = {
  handleResetAfterPreview: () => void;
  handleGenerate: () => void;
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
};

export const useSkip = ({
  handleResetAfterPreview,
  handleGenerate,
  apiPostResponse,
  apiGetResponse,
}: Props) => {
  const {
    activeFeatureName,
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    activateTargetStep,
  } = useFeaturesContext({});

  /* 白抜きスキップ */
  useEffect(() => {
    if (activeFeatureName !== 'white') return;
    if (!featureData?.mainImage.combinedBase64) return;
    if (apiPostResponse) return;
    if (apiGetResponse) return;
    if (featureData?.single?.genStatus.isGenerating) return;
    if (featureData?.single?.genStatus.isStop) {
      handleResetAfterPreview();
      updateFeatureDataSingle('genStatus', {
        isStop: false,
      });

      return;
    }
    console.log('skip white');
    void handleGenerate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeFeatureName,
    apiGetResponse,
    apiPostResponse,
    featureData?.single?.genStatus.isGenerating,
    featureData?.single?.genStatus.isStop,
    featureData?.mainImage.combinedBase64,
    activateTargetStep,
    // 依存配列に入れると二回生成されるので入れない
    // handleGenerate,
    handleResetAfterPreview,
    updateFeatureData,
  ]);
};
