import { z } from 'zod';

export const ImageSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  userId: z.string(),
  organizationId: z.string(),
  originalImageFormat: z.string(),
  fileName: z.string(),
  originalImageUrl: z.string(),
});
export type Image = z.infer<typeof ImageSchema>;
