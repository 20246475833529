import { Box } from '@/components/atoms/Box';
import KeepMaskIcon from '@/components/icons/others/KeepMaskIcon';
import { Popper } from '@/components/molecules/Popper';
import {
  StyledBoxHeight10,
  StyledBoxWidth10,
  StyledFlexCenter,
  StyledFlexGap5,
  StyledFontSx,
  StyledGridGap10,
} from '@/components/styled';
import { DOCUMENT_OF_KEEP_MASK_LINK } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { KeepMaskDialog } from '@/features/components/modals/KeepMaskDialog';
import { StyledGrayIconBox } from '@/features/components/styled/icon';
import { useDialog } from '@/hooks/local/useDialog';
import { usePopper } from '@/hooks/local/usePopper';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';
import { MdInfo } from 'react-icons/md';
import { TbExternalLink } from 'react-icons/tb';
import { StyledInfoPopperContainer } from './styled';

export const KeepMask = memo((): JSX.Element => {
  const { featureData, updateFeatureDataSingle } = useFeaturesContext({});
  const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const handleSubmit = () => {
    if (featureData?.mainImage.keepMaskCombinedBase64) {
      updateFeatureDataSingle('param', {
        keepMaskImage: featureData?.mainImage.keepMaskCombinedBase64,
      });
      // 固定アイテムがstep間を跨ぐためフォースクリアを設定（命名は今度考える）
      updateFeatureDataSingle('genStatus', {
        isForceClear: true,
      });
    }
    handleCloseDialog();
  };
  const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
    usePopper();

  return (
    <>
      <StyledBoxWidth10 />
      <StyledGridGap10>
        <Box
          sx={{
            position: 'relative',
            width: 112,
            height: 112,
            border: featureData?.single?.param?.keepMaskImage
              ? `2px solid ${'var(--color-primary-main)'}`
              : '1px solid #ccc',
            textAlign: 'center',
            verticalAlign: 'middle',
            borderRadius: '4px',
            lineHeight: '20px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
          onClick={handleOpenDialog}
        >
          <Box sx={{ position: 'absolute', zIndex: 2, top: -1, left: -1 }}>
            <KeepMaskIcon
              isActive={!!featureData?.single?.param?.keepMaskImage}
            />
          </Box>

          {featureData?.single?.param?.keepMaskImage ? (
            <ImageSpinner src={featureData?.single?.param?.keepMaskImage}>
              <Image
                src={featureData?.single?.param?.keepMaskImage}
                fill
                alt="pict"
                quality={90}
                style={{ objectFit: 'contain' }}
              />
            </ImageSpinner>
          ) : (
            <StyledFlexCenter sx={{ height: 112 }}>
              <StyledFontSx sx={{ color: 'var(--color-primary-deep)' }}>
                固定アイテム
                <br />
                を追加
              </StyledFontSx>
            </StyledFlexCenter>
          )}
        </Box>
        <StyledGrayIconBox
          sx={{ width: 24 }}
          onMouseOver={handleOpenPopper}
          onClick={handleClosePopper}
        >
          <MdInfo size={24} />
        </StyledGrayIconBox>
      </StyledGridGap10>

      <KeepMaskDialog
        isOpenDialog={isOpenDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        dialogTitle="固定アイテムを追加"
        dialogExplain="画像生成する際、変更せず元画像のままキープしたい領域を選択してください。"
      />

      <Popper
        title=""
        isOpenPopper={isOpenPopper}
        onClose={handleClosePopper}
        anchorElement={anchorElement}
        placement="bottom-start"
        placementY={10}
        hasClose={false}
        boxShadow="0px 4px 5px -2px rgba(0, 0, 0, 0.20), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)"
        borderRadius="4px"
        paddingRight="0px"
      >
        <StyledInfoPopperContainer>
          <StyledFontSx sx={{ fontSize: '12px', lineHeight: '20px' }}>
            画像生成する際、変更せず元画像のままキープしたい範囲を指定することができます。
          </StyledFontSx>
          <StyledBoxHeight10 />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            href={DOCUMENT_OF_KEEP_MASK_LINK}
            target="_blank"
            style={{
              color: 'var(--color-primary-deep)',
              textDecoration: 'none',
            }}
          >
            <StyledFlexGap5>
              <TbExternalLink size={24} />
              <StyledFontSx sx={{ fontSize: '12px' }}>
                テクニックブックを見る
              </StyledFontSx>
            </StyledFlexGap5>
          </Link>
        </StyledInfoPopperContainer>
      </Popper>
    </>
  );
});
