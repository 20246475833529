import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useCallback } from 'react';

export const useItemParam = () => {
  const { featureData } = useFeaturesContext({});

  const getItemRefImageParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      rough: false,
      generation_mode: 'generate_garment_by_reference_image',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
      ref_image: featureData?.refImage?.base64,
      ref_mask_image: featureData?.refImage?.maskBase64,
      keep_mask_image: featureData?.mainImage.keepMaskBase64 || null,
      parameters: {
        no_background: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.keepMaskBase64,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.isNoBackground,
    featureData?.refImage?.base64,
    featureData?.refImage?.maskBase64,
  ]);

  return {
    getItemRefImageParam,
  };
};
