import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useCallback } from 'react';

export const useColorParam = () => {
  const { featureData } = useFeaturesContext({});

  const getColorSingleColorParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      parameters: {
        color: featureData?.single?.param?.currentColor,
        no_background: featureData?.single?.param?.isNoBackground,
      },
      rough: false,
      generation_mode: 'generate_color_variation_single_rgb_color',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.currentColor,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.isNoBackground,
  ]);

  const getColorRefImageParam = useCallback(() => {
    const param = {
      n_sample: featureData?.single?.param?.generationCount,
      rough: false,
      generation_mode: 'generate_color_variation_single_color',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
      ref_image: featureData?.refImage?.base64,
      ref_mask_image: featureData?.refImage?.maskBase64,
      parameters: {
        no_background: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.isNoBackground,
    featureData?.refImage?.base64,
    featureData?.refImage?.maskBase64,
  ]);

  return {
    getColorSingleColorParam,
    getColorRefImageParam,
  };
};
