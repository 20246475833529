import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useValidateUploadImage } from '@/hooks/utils/useValidateUploadImage';
import { useEffect, useRef, useState } from 'react';
import { getImageSize } from 'react-image-size';
import { useUploadImageHelpers } from './useUploadImageHelpers';

type Props = {
  field: ImageFields;
  errorFiles: File[];
  setErrorFiles: (errorFiles: File[]) => void;
  setExceededFiles: (exceededFiles: File[]) => void;
};
export const usePasteImage = ({
  field,
  errorFiles,
  setErrorFiles,
  setExceededFiles,
}: Props) => {
  const { getImageInfo } = useUploadImageHelpers();
  const { validateImageFiles } = useValidateUploadImage();
  const newFiles: File[] = [];
  const newErrorFiles: File[] = [];
  const newExceededFiles: File[] = [];

  const { featureData, updateFeatureData } = useFeaturesContext({});
  const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();

  const MIN_SIZE = 500;

  const [imageData, setImageData] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePasteImage = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const isImage = event.clipboardData.items[0].type.indexOf('image') !== -1;
    if (isImage) {
      const imageFile = event.clipboardData.items[0].getAsFile();
      if (imageFile !== null) {
        const URLObj = window.URL || window.webkitURL;
        const imgSrc = URLObj.createObjectURL(imageFile);

        validateImageFiles(
          imageFile,
          newFiles,
          newExceededFiles,
          newErrorFiles,
        );
        if (newExceededFiles.length) {
          setExceededFiles(newExceededFiles);

          return;
        }
        if (newErrorFiles.length) {
          setErrorFiles(newErrorFiles);

          return;
        }

        setImageData((prev) => [...prev, imageFile]);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!inputRef.current) return;
      inputRef.current.focus();

      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        console.log('Ctrl+V (Cmd+V on Mac) pressed');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    console.log(imageData);
  }, [imageData]);

  useEffect(() => {
    if (featureData?.[field].file) return;
    if (!imageData.length) return;

    const file = imageData[0];
    const blobUrl = window.URL.createObjectURL(file);

    void (async () => {
      const dimension = await getImageSize(blobUrl);
      if (dimension.width < MIN_SIZE && dimension.height < MIN_SIZE) {
        setErrorFiles([...errorFiles, file]);

        return;
      }
      const { originalSize, resizedSize, base64 } = await getImageInfo(
        file,
        dimension,
        blobUrl,
      );

      updateFeatureData(field, {
        file,
        fileName: file.name,
        blobUrl,
        originalSize,
        resizedSize,
        base64,
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    featureData?.[field].file,
    field,
    updateFeatureData,
    imageData,
  ]);

  useEffect(() => {
    if (!featureData?.[field].base64) return;
    setSnackbarParam({
      iconName: 'success',
      text: `画像をアップロードしました`,
      position: { placement: 'rightTop', x: -320, y: 0 },
    });
    handleOpenSnackbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleOpenSnackbar,
    setSnackbarParam,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    featureData?.[field].base64,
  ]);

  return {
    imageData,
    handlePasteImage,
    inputRef,
  };
};
