import { useBackendApi } from '@/api';
import { Segmentation } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useError } from '@/hooks/global/useError';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  field: ImageFields;
};

/**
 * JSDoc
 * @see セグメンテーションの背景をリクエストするカスタムフック
 * @param {Props} { field }
 * @returns {Object} handleRequestBackground
 */
export const useRequestBackground = ({ field }: Props) => {
  const { logger } = useError();
  const { featureData, updateFeatureData, activeFeatureName } =
    useFeaturesContext({});
  const [apiResponse, setApiResponse] = useState<Segmentation | undefined>(
    undefined,
  );
  // 臨時対応・色温度の場合はtrue（外から受け取るべき？）
  const { postSegmentationBackground } = useBackendApi({
    isInverseResult: activeFeatureName === 'colorTemperature',
  });

  const handleRequestBackground = useCallback(async (): Promise<void> => {
    if (!featureData?.[field].base64) return;
    try {
      const base64 = featureData?.[field].base64 || '';
      updateFeatureData(field, {
        isPreviewLoading: true,
        maskBase64: '',
        combinedBase64: '',
      });
      setApiResponse(await postSegmentationBackground(base64));
    } catch (error) {
      console.log(error);
      logger({ error });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    featureData?.[field].base64,
    postSegmentationBackground,
  ]);

  useEffect(() => {
    if (!featureData?.[field].base64) return;
    if (!apiResponse) return;
    updateFeatureData(field, {
      isPreviewLoading: false,
      isPreviewCompleted: true,
      maskBase64: apiResponse.maskImageBase64,
      combinedBase64: apiResponse.maskOverlayImageBase64,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse, field, updateFeatureData, featureData?.[field].base64]);

  return {
    handleRequestBackground,
  };
};
