/* eslint-disable no-promise-executor-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useBackendApi } from '@/api/hooks/useBackendApi';
import { useBatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import { useError } from '@/hooks/global/useError';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { useEffect } from 'react';
import { FileResult, PostFile } from './types';

type Props = {
  handleOpenDialog: () => void;
  postFiles: PostFile[];
  setPostFiles: (value: PostFile[]) => void;
  setApiResponse: (value: FileResult[]) => void;
};

/**
 * JSDoc
 * @see base64画像をpostして画像URLを取得するカスタムフック
 */
export const usePostImages = ({
  handleOpenDialog,
  postFiles,
  setPostFiles,
  setApiResponse,
}: Props) => {
  const { logger } = useError();
  const { postImages, postSegmentationBackground } = useBackendApi({});
  const { batchConstant } = useBatchConstant();
  const { getImageMasking } = useImageMasking();
  const { setUploadingProgress } = useUploadingProgress();
  const { getResizedBase64 } = useResizeBase64();

  useEffect(() => {
    if (postFiles.length <= 0) return;
    handleOpenDialog();
    const total = postFiles.length;
    const increment = 100 / total;
    const uploadImages = async () => {
      const results: FileResult[] = [];

      for (const file of postFiles) {
        const fileResult: FileResult = {
          main: null,
          mask: null,
          url: null,
          base64: null,
          maskImageBase64: null,
          combinedImageBase64: null,
          combinedImageBase64Thumbnail120: null,
          combinedImageBase64Thumbnail268: null,
          combinedImageBase64Thumbnail512: null,
          originalSize: {
            width: file.width,
            height: file.height,
            size: file.size,
          },
        };
        try {
          const mainRes = await postImages({
            image: file.base64,
            fileName: file.fileName,
          });
          if (mainRes) {
            fileResult.main = mainRes;
            fileResult.base64 = file.base64 || null;
            fileResult.url = file.url || null;
          }

          // 領域選択の自動処理
          if (batchConstant && batchConstant.hasMainImageSam) {
            const backgroundRes = await postSegmentationBackground(file.base64);

            if (backgroundRes) {
              fileResult.maskImageBase64 = backgroundRes.maskImageBase64;
              try {
                const maskRes = await postImages({
                  image: file.base64,
                  fileName: `${file.fileName}-mask`,
                });
                if (maskRes) {
                  fileResult.mask = maskRes;
                }
                const combinedBase64 = await getImageMasking({
                  mainImageSource: file.base64,
                  maskImageSource: backgroundRes.maskImageBase64,
                });
                if (combinedBase64) {
                  fileResult.combinedImageBase64 =
                    backgroundRes?.maskOverlayImageBase64 || combinedBase64;
                  const [resized120, resized268, resized512] =
                    await Promise.all([
                      getResizedBase64({
                        targetSize: 120,
                        width: file.width,
                        height: file.height,
                        originalBase64: combinedBase64,
                      }),
                      getResizedBase64({
                        targetSize: 268,
                        width: file.width,
                        height: file.height,
                        originalBase64: combinedBase64,
                      }),
                      getResizedBase64({
                        targetSize: 512,
                        width: file.width,
                        height: file.height,
                        originalBase64: combinedBase64,
                      }),
                    ]);
                  fileResult.combinedImageBase64Thumbnail120 =
                    resized120.base64 || '';
                  fileResult.combinedImageBase64Thumbnail268 =
                    resized268.base64 || '';
                  fileResult.combinedImageBase64Thumbnail512 =
                    resized512.base64 || '';
                }
              } catch (error) {
                console.error(error);
                logger({ error });
              }
            }
          }
          results.push(fileResult);
          setUploadingProgress((prev: number) => prev + increment);
        } catch (error) {
          console.error(error);
          logger({ error });
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      setPostFiles([]);
      console.log(results);
      const filteredResults = results.filter(
        (result): result is FileResult =>
          result !== null && result !== undefined,
      );
      setApiResponse(filteredResults);
    };

    void uploadImages();
    /* 無限ループを回避 */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postFiles, setApiResponse, setPostFiles, setUploadingProgress]);
};
