import { ImageResult } from '@/contexts/FeaturesContext/types';
import { Swiper } from '@/features/components/organisms/GlobalViewer/Swiper';
import { SwiperThumbs } from '@/features/components/organisms/GlobalViewer/SwiperThumbs';
import { AnimatePresence } from 'framer-motion';
import React, { memo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  StyledGlobalViewerContainer,
  StyledSwiperMotionDiv,
  StyledSwiperThumbsMotionDiv,
} from './styled';

type Props = {
  imageResults: ImageResult[];
  label?: React.ReactNode;
  hasAnimation?: boolean;
  hasCheckIcon?: boolean;
  thumbnailBoxWidth?: number;
  repeatBackgroundImage?: string;
  taskType?: string;
};

export const GlobalViewer = memo(
  ({
    imageResults,
    label,
    hasAnimation = false,
    hasCheckIcon = false,
    thumbnailBoxWidth = 290,
    repeatBackgroundImage,
    taskType,
  }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);

    return (
      <StyledGlobalViewerContainer>
        <AnimatePresence initial={hasAnimation}>
          <StyledSwiperMotionDiv
            key="viewer-left"
            initial={{ x: hasAnimation ? 100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <Swiper
              imageResults={imageResults}
              swiperRef={swiperRef}
              setSwiperRef={setSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
              taskType={taskType}
            />
          </StyledSwiperMotionDiv>
          <StyledSwiperThumbsMotionDiv
            key="viewer-right"
            initial={{ x: hasAnimation ? -100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <SwiperThumbs
              imageResults={imageResults}
              label={label}
              hasCheckIcon={hasCheckIcon}
              thumbnailBoxWidth={thumbnailBoxWidth}
              swiperRef={swiperRef}
              setSwiperRef={setSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
            />
          </StyledSwiperThumbsMotionDiv>
        </AnimatePresence>
      </StyledGlobalViewerContainer>
    );
  },
);
