import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Popper } from '@/components/molecules/Popper';
import { useBatchDownload } from '@/features/components/steps/BatchDownload/hooks/useBatchDownload';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { usePopper } from '@/hooks/local/usePopper';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DataWithRequest } from './DataWithRequest';
import { StyledMenuBox } from './styled';

type Props = {
  handleOpenDialog: () => void;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  setTaskApiResponse: (task: Task | undefined) => void;
  retryId: string;
  setRetryId: (retryId: string) => void;
  batchId: string;
  setBatchId: (batchId: string) => void;
  isAllTasksDone: boolean;
  setIsAllTasksDone: (isAllTasksDone: boolean) => void;
  deletedIds: string[];
  setDeletedIds: (deletedIds: string[]) => void;
};

export const Body = memo(
  ({
    handleOpenDialog,
    handleOpenPopper,
    setTaskApiResponse,
    retryId,
    setRetryId,
    batchId,
    setBatchId,
    isAllTasksDone,
    setIsAllTasksDone,
    deletedIds,
    setDeletedIds,
  }: Props): JSX.Element => {
    const { featureData } = useBatchDownload();
    const [doneTotal, setDoneTotal] = useState<number>(0);
    const {
      isOpenPopper: isOpenDeletePopper,
      handleOpenPopper: handleOpenDeletePopper,
      handleClosePopper: handleCloseDeletePopper,
      anchorElement: anchorElementDelete,
    } = usePopper();
    const [setId, setSetId] = useState<string>('');
    const [hasRetryMenuItem, setHasRetryMenuItem] = useState<boolean>(false);
    const { setIsBatchGenerating } = useBatchOptionalContext();

    useEffect(() => {
      if (!featureData?.batch) return;
      if (isAllTasksDone) return;
      if (doneTotal >= featureData?.batch.length) {
        setIsAllTasksDone(true);
        setIsBatchGenerating(false);
      }
    }, [
      doneTotal,
      featureData?.batch,
      featureData?.batch.length,
      isAllTasksDone,
      setIsAllTasksDone,
      setIsBatchGenerating,
    ]);

    const handleOpenDeleteMenu = useCallback(
      (
        e: React.MouseEvent<HTMLButtonElement>,
        id: string,
        hasRetry?: boolean,
      ) => {
        handleOpenDeletePopper(e);
        setSetId(id);
        if (hasRetry) {
          setHasRetryMenuItem(true);
        }
      },
      [handleOpenDeletePopper],
    );
    const handleCloseDeleteMenu = useCallback(() => {
      handleCloseDeletePopper();
      setHasRetryMenuItem(false);
    }, [handleCloseDeletePopper]);

    const addSet = useCallback(() => {
      if (!setId) return;
      if (deletedIds.includes(setId)) return;
      setDeletedIds([...deletedIds, setId]);
      handleCloseDeletePopper();
    }, [setId, deletedIds, setDeletedIds, handleCloseDeletePopper]);

    const removeSet = useCallback(() => {
      if (!setId) return;
      setDeletedIds(deletedIds.filter((id) => id !== setId));
      handleCloseDeletePopper();
    }, [setId, deletedIds, setDeletedIds, handleCloseDeletePopper]);

    const isDeletedForMenu = useMemo(() => {
      if (deletedIds.includes(setId)) {
        return true;
      }

      return false;
    }, [deletedIds, setId]);

    useEffect(() => {
      console.log(deletedIds);
    }, [deletedIds]);

    return (
      <>
        {featureData?.batch.map(
          (set) =>
            set.refSam &&
            set.originalImages.length > 0 && (
              <>
                <DataWithRequest
                  key={`set-${set.id}`}
                  set={set}
                  handleOpenDialog={handleOpenDialog}
                  handleOpenPopper={handleOpenPopper}
                  setTaskApiResponse={setTaskApiResponse}
                  retryId={retryId}
                  setRetryId={setRetryId}
                  batchId={batchId}
                  setBatchId={setBatchId}
                  setDoneTotal={setDoneTotal}
                  handleOpenDeleteMenu={handleOpenDeleteMenu}
                  deletedIds={deletedIds}
                />
              </>
            ),
        )}
        <Box style={{ borderBottom: '1px solid var(--color-gray-light)' }} />
        <Popper
          isOpenPopper={isOpenDeletePopper}
          onClose={handleCloseDeleteMenu}
          anchorElement={anchorElementDelete}
          placement="right"
          placementY={25}
          hasClose={false}
          boxShadow="2px 2px 4px 0px rgba(0, 0, 0, 0.5)"
        >
          {hasRetryMenuItem && (
            <>
              <StyledMenuBox
                onClick={() => {
                  setRetryId(setId);
                  setHasRetryMenuItem(false);
                  handleCloseDeleteMenu();
                }}
              >
                生成をリトライ
              </StyledMenuBox>
            </>
          )}
          {!hasRetryMenuItem && isDeletedForMenu && (
            <StyledMenuBox onClick={removeSet}>
              セットの除外を解除
            </StyledMenuBox>
          )}
          {!hasRetryMenuItem && !isDeletedForMenu && (
            <StyledMenuBox onClick={addSet}>このセットを除外</StyledMenuBox>
          )}
        </Popper>
      </>
    );
  },
);
