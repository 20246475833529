import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useCallback } from 'react';

export const useWhiteParam = () => {
  const { featureData } = useFeaturesContext({});

  const getWhiteColorParam = useCallback(() => {
    const param = {
      n_sample: 1,
      rough: false,
      generation_mode: 'misc_background_removal',
      original_image: featureData?.mainImage.base64,
      original_image_file_name: featureData?.mainImage.fileName,
      mask_image: featureData?.mainImage.maskBase64,
      parameters: {
        no_background: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.fileName,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.isNoBackground,
  ]);

  return {
    getWhiteColorParam,
  };
};
