import { User } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFont,
  StyledFont18Bold,
  StyledGridGap10,
} from '@/components/styled';
import { useIcons } from '@/hooks/utils/useIcons';
import { returnConvertedDate } from '@/utils/date';
import { memo, useCallback } from 'react';
import {
  StyledBoxWidth420,
  StyledGridGap24WidthExpanded,
  StyledMessageBox,
  StyledStatusBox,
} from './styled';

import {
  StyledDialogDeleteUserBorderButton,
  StyledDialogDeleteUserButton,
  StyledDialogSendInvitationButton,
} from '@/components/styled/button';

type Props = {
  dialogTitle: string;
  targetUser: User | null;
  isDeletedUser: boolean;
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleDeleteUser: (email: string, id: string) => Promise<void>;
  handleReInviteUser: (email: string, id: string) => Promise<void>;
};

export const AdminControlUserDialog = memo(
  ({
    targetUser,
    isDeletedUser,
    isOpenDialog,
    dialogTitle,
    handleCloseDialog,
    handleDeleteUser,
    handleReInviteUser,
  }: Props): JSX.Element => {
    const { returnIcon } = useIcons();

    const handleSubmitDeleteUser = useCallback(() => {
      if (!targetUser) return;
      void handleDeleteUser(targetUser.email, targetUser.id);
      handleCloseDialog();
    }, [handleCloseDialog, handleDeleteUser, targetUser]);

    const handleSubmitReInviteUser = useCallback(() => {
      if (!targetUser) return;
      void handleReInviteUser(targetUser.email, targetUser.id);
      handleCloseDialog();
    }, [handleCloseDialog, handleReInviteUser, targetUser]);

    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle={dialogTitle}
        >
          <StyledBoxWidth420>
            <StyledGridGap24WidthExpanded>
              {targetUser?.deletedAt && (
                <StyledMessageBox>
                  このユーザーは削除されています
                </StyledMessageBox>
              )}
              {!targetUser?.lastLoginAt && (
                <StyledMessageBox>
                  このユーザーは認証を待っています
                </StyledMessageBox>
              )}
              <StyledStatusBox>
                <Box>{returnIcon(isDeletedUser ? 'remove' : 'user')}</Box>
                <Box>
                  <StyledFont18Bold>{targetUser?.email}</StyledFont18Bold>
                  <StyledGridGap10>
                    <Box>
                      アカウント作成
                      <StyledFont>
                        {targetUser?.createdAt &&
                          returnConvertedDate(targetUser?.createdAt)}
                      </StyledFont>
                    </Box>
                    {targetUser?.lastLoginAt && (
                      <Box>
                        最終ログイン
                        <StyledFont>
                          {returnConvertedDate(targetUser?.lastLoginAt)}
                        </StyledFont>
                      </Box>
                    )}

                    {isDeletedUser && (
                      <Box>
                        アカウント削除
                        <StyledFont>
                          {targetUser?.deletedAt &&
                            returnConvertedDate(targetUser?.deletedAt)}
                        </StyledFont>
                      </Box>
                    )}
                  </StyledGridGap10>
                </Box>
              </StyledStatusBox>
            </StyledGridGap24WidthExpanded>
          </StyledBoxWidth420>
          <StyledBoxHeight24 />
          <StyledBoxWidth420>
            <StyledFlexEnd>
              <StyledFlexGap10>
                {!targetUser?.lastLoginAt ? (
                  <StyledDialogDeleteUserButton
                    onClick={handleSubmitDeleteUser}
                  >
                    このユーザーを削除する
                  </StyledDialogDeleteUserButton>
                ) : (
                  <StyledDialogDeleteUserBorderButton
                    onClick={handleSubmitDeleteUser}
                  >
                    このユーザーを削除する
                  </StyledDialogDeleteUserBorderButton>
                )}

                {!targetUser?.lastLoginAt && (
                  <StyledDialogSendInvitationButton
                    onClick={() => {
                      handleSubmitReInviteUser();
                    }}
                  >
                    招待を再送信
                  </StyledDialogSendInvitationButton>
                )}
              </StyledFlexGap10>
            </StyledFlexEnd>
          </StyledBoxWidth420>
          <StyledBoxHeight24 />
        </Dialog>
      </>
    );
  },
);
