import {
  TableCellProps,
  TableRowProps,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type TrProps = {
  status: string;
} & TableRowProps;

export const StyledTr = memo(({ status, children }: TrProps) => {
  return (
    <Tr
      className={
        status === 'IN_PROGRESS'
          ? vanilla.styledColumnInProgress
          : status === 'FAILED' || status === 'TIMEOUT'
          ? vanilla.styledColumnAnother
          : vanilla.styledColumnCompleted
      }
    >
      {children}
    </Tr>
  );
});

export const StyledTdFile = memo(({ children }: TableCellProps) => {
  return <Td className={vanilla.styledTdFile}>{children}</Td>;
});

export const StyledTdFixed = memo(({ children }: TableCellProps) => {
  return <Td className={vanilla.styledTdFixed}>{children}</Td>;
});
