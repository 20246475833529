import { Box } from '@/components/atoms/Box';
import { StyledFlex, StyledFlexEnd, StyledFontSx } from '@/components/styled';
import { BATCH_LIMIT_IMAGES } from '@/constants';

import {
  StyledErrorIcon,
  StyledErrorIconBox,
} from '@/features/components/atoms/ErrorCard/styled';
import { BatchWarnings } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { memo, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

type Props = {
  warningsData: BatchWarnings;
};

export const WarningsDisplay = memo(({ warningsData }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const [hasWarning, setHasWarning] = useState(false);

  useEffect(() => {
    setHasWarning(
      warningsData.formatErrorFiles.length > 0 ||
        warningsData.dataSizeErrorFiles.length > 0 ||
        warningsData.imageSizeErrorFiles.length > 0 ||
        warningsData.duplicatedErrorFiles.length > 0 ||
        warningsData.exceededImageLimitErrorFiles.length > 0 ||
        warningsData.resizedImageFiles?.length > 0,
    );
  }, [warningsData]);

  return (
    <>
      {hasWarning && (
        <Box
          style={{
            padding: 16,
            borderRadius: '4px',
            backgroundColor: '#fdeded',
            color: '#5f2120',
            width: 280,
          }}
        >
          <StyledFlex
            onClick={() => setIsOpen(!isOpen)}
            style={{
              alignItems: 'center',
              gap: '5px',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Box style={{ height: 26 }}>
              <StyledErrorIconBox data-testid="test-icon">
                <StyledErrorIcon />
              </StyledErrorIconBox>
            </Box>
            <StyledFontSx
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              エラー
            </StyledFontSx>
            <StyledFlexEnd>
              {isOpen ? (
                <MdKeyboardArrowUp size={20} />
              ) : (
                <MdKeyboardArrowDown size={20} />
              )}
            </StyledFlexEnd>
          </StyledFlex>
          <Box
            style={{
              whiteSpace: 'pre-line',
              overflowWrap: 'anywhere',
            }}
          >
            {isOpen && (
              <>
                <Box>
                  {warningsData.formatErrorFiles.length > 0 && (
                    <>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.formatErrorFiles.map((file: File) => (
                          <li
                            key={`format-error-${file.name}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>

                      <StyledFontSx>
                        こちらのファイルは使用出来ません
                      </StyledFontSx>
                    </>
                  )}
                </Box>
                <Box>
                  {warningsData.dataSizeErrorFiles.length > 0 && (
                    <Box>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.dataSizeErrorFiles.map((file: File) => (
                          <li
                            key={`data-size-error-${file.name}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>

                      <StyledFontSx>
                        ファイル容量が5MBを超えています
                      </StyledFontSx>
                    </Box>
                  )}
                </Box>
                <Box>
                  {warningsData.imageSizeErrorFiles.length > 0 && (
                    <Box>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.imageSizeErrorFiles.map((file: File) => (
                          <li
                            key={`data-size-error-${file.name}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>

                      <StyledFontSx>
                        500x500px以下の画像は使用出来ません
                      </StyledFontSx>
                    </Box>
                  )}
                </Box>
                <Box>
                  {warningsData.duplicatedErrorFiles.length > 0 && (
                    <Box>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.duplicatedErrorFiles.map((file: File) => (
                          <li
                            key={`duplicated-error-${file.name}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>

                      <StyledFontSx>
                        同一のファイルはアップロード出来ません
                      </StyledFontSx>
                    </Box>
                  )}
                </Box>
                <Box>
                  {warningsData.exceededImageLimitErrorFiles.length > 0 && (
                    <Box>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.exceededImageLimitErrorFiles.map(
                          (file: File) => (
                            <li
                              key={`exceeded-image-limit-error-${file.name}`}
                              style={{ fontWeight: 'bold' }}
                            >
                              {file.name}
                            </li>
                          ),
                        )}
                      </ul>

                      <StyledFontSx>
                        1セットにつき{BATCH_LIMIT_IMAGES}
                        枚を超えるアップロードは出来ません
                      </StyledFontSx>
                    </Box>
                  )}
                </Box>
                <Box>
                  {warningsData.resizedImageFiles.length > 0 && (
                    <Box>
                      <ul
                        style={{
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          maxWidth: 240,
                        }}
                      >
                        {warningsData.resizedImageFiles.map((file: File) => (
                          <li
                            key={`resized-image-${file.name}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>

                      <StyledFontSx>
                        規定サイズを超えたためリサイズされました
                      </StyledFontSx>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
});
