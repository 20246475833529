import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledTitle = memo(({children}) => {
  return <span className={vanilla.styledTitle}>{children}</span>
})

export const StyledTabButtonList = memo(({ children }) => {
  return (
    <ul className={vanilla.styledTabButtonList}>
      {children}
    </ul>
  );
});

export const StyledTabButtonListChild = memo(({ children }) => {
  return (
    <li className={vanilla.styledTabButtonListChild}>
      {children}
    </li>
  );
});
