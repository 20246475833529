/* eslint-disable import/newline-after-import */
import type { SVGProps } from 'react';
import { memo } from 'react';
const ActiveTriangle = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <g clipPath="url(#activeTriangle_svg__a)">
        <mask id="activeTriangle_svg__b" fill="#fff">
          <path
            fillRule="evenodd"
            d="M0 16 8 0l8 16h56a4 4 0 0 1 4 4v88a4 4 0 0 1-4 4h-264a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4H0Z"
            clipRule="evenodd"
          />
        </mask>
        <path
          fill="#EDF7FE"
          fillRule="evenodd"
          d="M0 16 8 0l8 16h56a4 4 0 0 1 4 4v88a4 4 0 0 1-4 4h-264a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4H0Z"
          clipRule="evenodd"
        />
        <path
          fill="#42A5F5"
          d="m8 0-.894-.447L8-2.237l.894 1.79L8 0ZM0 16l.894.447L.618 17H0v-1Zm16 0v1h-.618l-.276-.553L16 16ZM8.894.447l-8 16-1.788-.894 8-16 1.788.894Zm6.212 16-8-16 1.788-.894 8 16-1.788.894ZM72 17H16v-2h56v2Zm3 3a3 3 0 0 0-3-3v-2a5 5 0 0 1 5 5h-2Zm0 88V20h2v88h-2Zm-3 3a3 3 0 0 0 3-3h2a5 5 0 0 1-5 5v-2Zm-264 0H72v2h-264v-2Zm-3-3a3 3 0 0 0 3 3v2a5 5 0 0 1-5-5h2Zm0-88v88h-2V20h2Zm3-3a3 3 0 0 0-3 3h-2a5 5 0 0 1 5-5v2ZM0 17h-192v-2H0v2Z"
          mask="url(#activeTriangle_svg__b)"
        />
      </g>
      <defs>
        <clipPath id="activeTriangle_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});
export default ActiveTriangle;
