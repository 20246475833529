import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledFlexGap10,
  StyledGridGap10,
} from '@/components/styled';
import { FeaturePresetImage } from '@/contexts/FeaturesContext/types';
import { TabButton } from '@/features/components/atoms/TabButton/TabButton';
import { Button as MUIButton } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useEffect, useState } from 'react';
import { RowData } from './RowData';
import { useRequestPresets } from './hooks/useRequestPresets';
import { StyledPresetContainer, StyledRowContainer } from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dialogTitle: string;
  onSubmit: (presetValue: FeaturePresetImage) => void;
};

export const BackgroundPresetDialog = memo(
  ({ isOpen, onClose, dialogTitle, onSubmit }: Props): JSX.Element => {
    const [targetValue, setTargetValue] = useState<string>('');
    const [presetValue, setPresetValue] = useState<
      FeaturePresetImage | undefined
    >({
      id: '',
      displayName: '',
      examples: [],
    });
    const [tabValue, setTabValue] = useState<string>('default');
    const { apiResponse } = useRequestPresets();

    useEffect(() => {
      if (targetValue) {
        setPresetValue(
          apiResponse?.data.find((preset) => preset.id === targetValue),
        );
      }
    }, [targetValue, apiResponse?.data]);

    return (
      <Dialog
        isOpenDialog={isOpen}
        onClose={onClose}
        maxWidth="xl"
        dialogTitle={dialogTitle}
        hasCloseIcon={false}
      >
        <StyledPresetContainer>
          <Box style={{ height: 60 }}>
            <StyledFlexGap10>
              <TabButton
                onClick={() => {
                  setTabValue('default');
                }}
                label="デフォルト"
                isActive
              />
              {/* <TabButton
                label="特注（準備中）"
                isActive={false}
                onClick={() => {
                  setTabValue('special');
                }}
              /> */}
              {/* 準備中の機能なので特別に装飾を変える（準備が終わったらTabButtonに差し替える） */}
              <MUIButton
                type="button"
                disabled
                sx={{
                  width: '100%',
                  padding: '9px 16px',
                  textAlign: 'center',
                  fontSize: 'var(--font-size-md)',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  letterSpacing: '0.4px',
                  cursor: 'not-allowed',

                  borderRadius: 0,
                  borderBottom: `1px solid ${hexToRgba('#000', 0.12)}`,
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
              >
                特注（準備中）
              </MUIButton>
            </StyledFlexGap10>
          </Box>
          <StyledRowContainer>
            <StyledGridGap10>
              {apiResponse &&
                apiResponse.data.map((preset) => (
                  <RowData
                    preset={preset}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                  />
                ))}
            </StyledGridGap10>
          </StyledRowContainer>
          <StyledBoxHeight24 />
          <Box style={{ height: 60 }}>
            <StyledFlexGap10>
              <Button
                mode="border"
                style={{ height: 32, fontSize: 14, width: '50%' }}
                onClick={() => {
                  onClose();
                }}
              >
                キャンセル
              </Button>
              <Button
                style={{ height: 32, fontSize: 14, width: '50%' }}
                onClick={() => {
                  if (presetValue) {
                    onSubmit(presetValue);
                  }
                }}
                disabled={!presetValue}
              >
                決定
              </Button>
            </StyledFlexGap10>
          </Box>
        </StyledPresetContainer>
      </Dialog>
    );
  },
);
