import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

const checkArrayAtom = atom<boolean[]>([]);
const downloadedArrayAtom = atom<boolean[]>([]);

/**
 * JSDoc
 * @see SwiperThumbsのチェックとダウンロード状態を管理するカスタムフック
 * @see arrayの初期化は呼び出し元で行う
 * @returns {Object}
 */
export const useCheckArray = () => {
  const [checkArray, setCheckArray] = useAtom(checkArrayAtom);
  const [downloadedArray, setDownloadedArray] = useAtom(downloadedArrayAtom);

  // useMemoを使用してcheckedCountを計算
  const checkedCount = useMemo(
    () => checkArray.filter(Boolean).length,
    [checkArray],
  );

  const initializeCheckArray = useCallback(() => {
    setCheckArray([]);
    setDownloadedArray([]);
  }, [setCheckArray, setDownloadedArray]);

  const checkIndex = useCallback(
    (index: number) => {
      const newCheckArray = checkArray.map((item, i) =>
        i === index ? !item : item,
      );
      setCheckArray(newCheckArray);
    },
    [checkArray, setCheckArray],
  );

  const handleCheckAll = useCallback(() => {
    setCheckArray(checkArray.map(() => true));
  }, [checkArray, setCheckArray]);

  const handleUnCheckAll = useCallback(() => {
    setCheckArray(checkArray.map(() => false));
  }, [checkArray, setCheckArray]);

  const findChecked = useCallback(
    (index: number) => checkArray[index] === true,
    [checkArray],
  );

  const handleFindCheckedOne = useCallback(
    () => checkArray.some((d) => d === true),
    [checkArray],
  );

  const findDownloaded = useCallback(
    (index: number) => downloadedArray[index] === true,
    [downloadedArray],
  );

  useEffect(() => {
    return () => {
      initializeCheckArray();
    };
  }, [initializeCheckArray]);

  return {
    checkArray,
    setCheckArray,
    downloadedArray,
    setDownloadedArray,
    checkedCount,
    initializeCheckArray,
    checkIndex,
    handleCheckAll,
    handleUnCheckAll,
    findChecked,
    findDownloaded,
    handleFindCheckedOne,
  };
};
