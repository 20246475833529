/* eslint-disable import/newline-after-import */
import type { SVGProps } from 'react';
import { memo } from 'react';
const MultiUploadImages = memo(
  (props: SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
        {...props}
      >
        <rect
          width={20}
          height={22}
          x={4.5}
          y={3}
          stroke="#BDBDBD"
          strokeWidth={2}
          rx={1}
        />
        <path fill="#fff" d="M11.5 0h6l5 7h-16z" />
        <path
          fill="#BDBDBD"
          fillRule="evenodd"
          d="m14.5 0-6 8h4v8h4V8h4z"
          clipRule="evenodd"
        />
        <path stroke="#BDBDBD" strokeWidth={2} d="M6.5 28h19a2 2 0 0 0 2-2V5" />
        <path stroke="#BDBDBD" strokeWidth={2} d="M9.5 31h19a2 2 0 0 0 2-2V8" />
      </svg>
    );
  },
);
export default MultiUploadImages;
