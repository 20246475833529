/**
 * @see Sugekae内で使用する定数を定義している
 */
export const TASK_STATUS = {
  IN_QUEUE: '生成準備中',
  IN_PROGRESS: '生成中',
  COMPLETED: '生成完了',
  FAILED: '生成失敗',
  CANCELED: '生成キャンセル',
  TIME_OUT: 'タイムアウト',
};

export const TASK_STATUS_MOCK = {
  jp: { generating: '生成中', generated: '生成完了', error: 'エラー' },
  en: { generating: 'generating', generated: 'generated', error: 'error' },
};

export const FEATURES_NAME = {
  home: 'ホーム',
  background: '背景',
  color: 'カラー',
  item: 'アイテム',
  model: 'モデル',
  gallery: 'ギャラリー',
  task: 'タスク',
};

/* 背景やカラバリ、アイテムなどで名称を変えるのを避けるため機能のターゲットのタイプとしている */
export const FEATURE_TARGET_TYPE = {
  color: '単色',
  image: '画像',
};

export const UPLOAD_MAX_SIZE = {
  background: 2048,
  white: 2560,
  color: 2048,
  item: 1200,
};

export const LOADING_PROGRESS_TIPS = [
  {
    text: '白い空間の背景を生成する場合は、真っ白ではなく少しグレーよりの白を選択しましょう',
  },
  {
    text: '背景の変更、背景白抜きを行う際は被写体以外に人物が存在しない画像を使用しましょう',
  },
  {
    text: '生成結果に納得できない時は、プレビュー数を増やして何度か生成してみましょう',
  },
  {
    text: 'カラーやアイテムをすげ替える際、選択範囲はなるべく細かく指定しましょう',
  },
  {
    text: '画像を参照して生成を行う場合、元画像との明るさの差が小さい画像を使用すると自然な明るさで生成されます',
  },
  {
    text: 'プレビュー数を増やすと生成に時間がかかります、エラーではないのでご安心ください',
  },
  {
    text: 'アイテム生成は「元の画像」と「着せたい画像」共にモデル着用画像で、さらにポージングも似ていると自然な生成が行われます',
  },
  {
    text: 'プレビューイメージに並んでいる「SAMPLE」の文字はダウンロードすると消去されます',
  },
  {
    text: '設定を直したい時は、画面下のヒストリー内のサムネイルをクリックすると前の工程に戻ることができます',
  },
  {
    text: '「画像を変えてリピート生成」はダウンロード後に使用可能です',
  },
];

export const FEATURE_JP_NAMES_FROM_BACKEND = {
  background: '背景',
  misc: '白抜き',
  color: 'カラー',
  outfit: 'アイテム',
};

export const FEATURE_EN_NAMES_FROM_BACKEND = {
  background: 'background',
  misc: 'white',
  color: 'color',
  outfit: 'item',
};

export const TASK_TYPE_TO_JP_MODE_FROM_BACKEND = {
  generate_background_single_color: '背景（1枚）',
  generate_background_by_reference_image: '背景（1枚）',
  generate_background_by_preset: '背景（1枚）',
  misc_background_removal: '白抜き',
  generate_color_variation_single_rgb_color: 'カラー',
  generate_color_variation_single_color: 'カラー',
  generate_garment_by_reference_image: 'アイテム',
  generate_color_correction: '色温度',
  generate_background_by_reuse_image: '背景（複数）',
};

export const TASK_TYPE_TO_EN_MODE_FROM_BACKEND = {
  generate_background_single_color: 'background',
  generate_background_by_reference_image: 'background',
  generate_background_by_preset: 'background',
  misc_background_removal: 'white',
  generate_color_variation_single_rgb_color: 'color',
  generate_color_variation_single_color: 'color',
  generate_garment_by_reference_image: 'item',
  generate_color_correction: 'colorTemperature',
  generate_background_by_reuse_image: 'backgroundLora',
};

export const GENERATION_NAMES_FROM_BACKEND = {
  generate_background_single_color: '単色指定',
  generate_background_by_reference_image: '画像参照',
  misc_background_removal: '背景白指定',
  generate_color_variation_single_rgb_color: '単色指定',
  generate_color_variation_single_color: '画像参照',
  generate_garment_by_reference_image: '画像参照',
  generate_background_by_reuse_image: '画像参照',
};

export const DOCUMENT_OF_BLUR_LINK =
  'https://liquidinc.atlassian.net/wiki/spaces/Sugekae/pages/2787049484/SugeKae+Technique+Book#%E3%83%BB%E8%83%8C%E6%99%AF%E3%81%AB%E3%81%BC%E3%81%8B%E3%81%97%E3%82%92%E5%8A%A0%E3%81%88%E3%82%8B';
export const DOCUMENT_OF_KEEP_MASK_LINK =
  'https://liquidinc.atlassian.net/wiki/spaces/Sugekae/pages/2787049484/SugeKae+Technique+Book#%E3%83%BB%E5%9B%BA%E5%AE%9A%E3%82%A2%E3%82%A4%E3%83%86%E3%83%A0%E3%82%92%E8%BF%BD%E5%8A%A0';

export const DOCUMENT_OF_DOWNLOAD_FORMAT =
  'https://liquidinc.atlassian.net/wiki/spaces/Sugekae/pages/2834268161/Copy+of+SugeKae+Technique+Book#%E2%96%A0SugeKae%E3%81%A7%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E5%8F%AF%E8%83%BD%E3%81%AA%E5%90%84%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E5%BD%A2%E5%BC%8F%E3%81%AE%E7%89%B9%E5%BE%B4';

export const UPLOAD_IMAGE_LIMIT_BYTES = 5 * 1024 * 1024; // 5MB

export const BATCH_MAX_SIZE = {
  backgroundLora: 2048,
  colorTemperature: 2560,
};

export const BATCH_LIMIT_SET = 5;
export const BATCH_LIMIT_IMAGES = 20;

export const Z_INDEX = {
  popperDefault: 10000,
  dialogDefault: 10001,
  popperOnDialog: 10002,
};
