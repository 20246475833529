import { Box } from '@/components/atoms/Box';
import {
  StyledFlexCenter,
  StyledFlexGap10,
  StyledGridEnd,
  StyledGridGap25,
} from '@/components/styled';
import {
  StyledFunctionClearButton,
  StyledFunctionGenerateButton,
  StyledFunctionNextButton,
} from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { NoBackgroundSwitch } from '@/features/components/organisms/NoBackgroundSwitch';
import { memo } from 'react';

type Props = {
  handleGenerate: () => void;
  handleClear: () => void;
  handleResetAfterPreview: () => void;
};
export const Buttons = memo(
  ({
    handleGenerate,
    handleClear,
    handleResetAfterPreview,
  }: Props): JSX.Element => {
    const { featureData, activateTargetStep } = useFeaturesContext({});

    const genButtonDisabled = () => {
      const isAlreadyGenerated = featureData?.single?.genStatus?.isGenerated;
      const hasNoColorAndRefAndPreset =
        !featureData?.single?.param?.currentColor &&
        !featureData?.single?.param?.currentRef &&
        !featureData?.single?.param?.presetUrl;

      return isAlreadyGenerated || hasNoColorAndRefAndPreset;
    };

    return (
      <>
        <StyledGridEnd>
          <StyledFlexCenter>
            <StyledGridGap25>
              <NoBackgroundSwitch
                handleResetAfterPreview={handleResetAfterPreview}
              />
              <StyledFlexGap10>
                <StyledFunctionGenerateButton
                  onClick={() => {
                    void handleGenerate();
                  }}
                  disabled={genButtonDisabled()}
                >
                  生成
                  {featureData?.single?.genStatus?.isGenerated ? '済み' : ''}
                </StyledFunctionGenerateButton>
                <StyledFunctionClearButton
                  onClick={handleClear}
                  disabled={!featureData?.single?.genStatus.isEditing}
                >
                  クリア
                </StyledFunctionClearButton>
              </StyledFlexGap10>
              <StyledFunctionNextButton
                onClick={() => {
                  activateTargetStep('download');
                }}
                disabled={!featureData?.single?.genStatus?.isGenerated || false}
              >
                次へ
              </StyledFunctionNextButton>
              <Box />
            </StyledGridGap25>
          </StyledFlexCenter>
        </StyledGridEnd>
      </>
    );
  },
);
