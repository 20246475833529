import { Box } from '@/components/atoms/Box';
import { Tooltip } from '@/components/atoms/Tooltip';
import { CheckedRef } from '@/components/icons';
import {
  StyledFlex,
  StyledFlexGap10,
  StyledFlexGap20,
  StyledFontSx
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import {
  StyledDividerBox,
  StyledTableRowContainer,
  StyledTableRowName,
  StyledTableRowNameEllipsis,
  StyledTableRowNameFlex,
} from '@/features/components/styled/batch';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider, IconButton } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

type Props = {
  index: number;
  set: FeatureBatch;
  handleChangeCurrentSet: (id: string) => void;
  checkUploadImageCondition: (id: string) => string | boolean;
  isOpenAccordion: boolean;
  handleChangeAccordionState: (id: string) => void;
  currentNumber: number;
};

export const Bar = memo(
  ({
    index,
    set,
    handleChangeCurrentSet,
    checkUploadImageCondition,
    isOpenAccordion,
    handleChangeAccordionState,
    currentNumber,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});
    const {
      isCollectiveSetting,
      collectiveRefImage,
      collectivePresetImage,
    } = useBatchOptionalContext()

    // TODO: useFunctionsCoordinateParamsの一部をそのままコピペしている。どこからでも参照できるように別の共通ロジック（util的な何か）に切り出す。
    const backgroundUrl = useMemo(() => {
      if (!featureData) return '';
      if (!collectiveRefImage) return '';
      if (isCollectiveSetting && collectiveRefImage.refUrl) {
        return collectiveRefImage.refUrl;
      }
      if (
        featureData &&
        featureData.batch[currentNumber] &&
        featureData?.batch[currentNumber].refImage.refUrl
      ) {
        return featureData?.batch[currentNumber].refImage.refUrl;
      }

      return '';
    }, [collectiveRefImage, currentNumber, featureData, isCollectiveSetting]);

    const presetData = useMemo(() => {
      if (!featureData) return undefined;

      if (
        isCollectiveSetting &&
        collectivePresetImage &&
        collectivePresetImage?.examples?.length > 0
      ) {
        return {
          displayName: collectivePresetImage.displayName,
          url: collectivePresetImage.examples[0].url,
        }
      }
      if (
        featureData &&
        featureData.batch[currentNumber] &&
        featureData.batch[currentNumber]?.presetImage?.examples?.length > 0
      ) {
        return {
          url: featureData.batch[currentNumber].presetImage.examples[0].url
        };
      }

      return undefined;
    }, [collectivePresetImage, currentNumber, featureData, isCollectiveSetting]);

    const { convertToDateTime } = useConvertDate();

    const textColor = useMemo(
      () =>
        set.isCurrent
          ? checkUploadImageCondition(set.id)
            ? hexToRgba('#000', 1)
            : hexToRgba('#000', 0.38)
          : '#999',
      [set.id, set.isCurrent, checkUploadImageCondition],
    );

    // TODO: 簡単のためコンポーネント内に記述。余裕があるときに適切なカスタムフックに切り出し、他コンポーネントでも使えるようにする
    const targetBackgroundType = useMemo(() => {
      if (isCollectiveSetting) {
        // TODO: 現状一括設定モードでtargetTypeを変更してもcollectiveSettingParam.targetTypeが変わらずfeatureDataの方が変わるので、いったんfeatureDataのtargetTypeを参照するようにしておく。どこかのタイミングで修正する。
        // MEMO: 現状一括設定モードではcurrentNumberの設定を参照しているためこちらもその仕様に合わせる。必要であればその仕様自体を変更する。
        return featureData?.batch[currentNumber].mainParam?.targetType === 'preset'
          && presetData?.url ? 'preset'
          : featureData?.batch[currentNumber].mainParam?.targetType === 'image'
            && backgroundUrl ? 'image'
            : undefined
      }

      return featureData?.batch[index].mainParam?.targetType === 'preset'
        && featureData?.batch[index].presetImage?.id ? 'preset'
        : featureData?.batch[index].mainParam?.targetType === 'image'
          && featureData?.batch[index].refImage?.refImageId ? 'image'
          : undefined
    }, [
      featureData?.batch,
      isCollectiveSetting,
      backgroundUrl,
      presetData?.url,
      currentNumber,
      index,
    ])

    return (
      <>
        <StyledTableRowContainer
          sx={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
            padding: '8px 32px',
          }}
          key={`set-${set.id}`}
          onClick={() => handleChangeCurrentSet(set.id)}
        >
          <StyledTableRowName>
            <StyledTableRowNameFlex sx={{ textColor, gap: '10px' }}>
              <Box
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                {set.originalImages.length > 0 && set.originalImages[0].url && (
                  <img
                    src={`${set.originalImages[0].url}&d=56x56`}
                    alt={`画像`}
                    width={56}
                    height={56}
                    style={{
                      width: 56,
                      height: 56,
                      border: '1px solid #ccc',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                )}
              </Box>
              <StyledFlexGap10>
                <StyledTableRowNameEllipsis style={{ color: textColor }}>
                  <Tooltip title={set.setName} placement="bottom-start">
                    {set.setName}
                  </Tooltip>
                </StyledTableRowNameEllipsis>
              </StyledFlexGap10>
            </StyledTableRowNameFlex>
          </StyledTableRowName>
          <Box style={{ width: 100, maxWidth: 100 }}>{''}</Box>
          <StyledDividerBox>
            <Divider orientation="vertical" sx={{ backgroundColor: '#ddd' }} />
          </StyledDividerBox>
          <Box style={{ width: 200 }}>
            <StyledFontSx
              sx={{
                color: textColor,
              }}
            >
              {set.uploadedAt
                ? convertToDateTime(set.uploadedAt as unknown as string)
                : 'ー'}
            </StyledFontSx>
          </Box>
          <Box style={{ width: 150, textAlign: 'center' }}>
            <StyledFontSx
              sx={{
                color: textColor,
              }}
            >
              {set.originalImages.length || 'ー'}
            </StyledFontSx>
          </Box>
          <StyledFlex style={{ width: 150, paddingLeft: 8 }}>
            {targetBackgroundType ? (
              <>
                <Box sx={{marginRight: '8px'}}>
                  <CheckedRef />
                </Box>
                <span>
                  {targetBackgroundType === 'preset' ? 'プリセット' : 'イメージ'}
                </span>
              </>
            ) : '-'}
          </StyledFlex>
          <Box style={{ width: 100, maxWidth: 100 }}>
            <StyledFlexGap20>
              <Box style={{ width: 20 }} />

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeAccordionState(set.id);
                }}
              >
                {checkUploadImageCondition(set.id) ? (
                  <>
                    {isOpenAccordion ? (
                      <MdKeyboardArrowUp size={20} />
                    ) : (
                      <MdKeyboardArrowDown size={20} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </IconButton>
            </StyledFlexGap20>
          </Box>
        </StyledTableRowContainer>
      </>
    );
  },
);
