/* eslint-disable import/newline-after-import */
import { memo } from 'react';
type Props = {
  isActive: boolean;
};
const KeepMaskIcon = memo(({ isActive }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <rect
        width={32}
        height={32}
        fill={isActive ? '#2196f3' : '#BDBDBD'}
        rx={4}
      />
      <path
        fill="#fff"
        d="M19.251 9.105c-.746-1.128-.708-2.112-.523-2.562l6.272 4.9c-.4.281-1.341.56-2.612.111l-2.769 3.544c1.757 2.219 1.366 4.446.823 5.411l-9.408-7.35c.828-.742 2.878-1.672 5.449-.51zM14.342 17.005l1.568 1.225-4.9 6.273-2.01.955.441-2.18z"
      />
    </svg>
  );
});
export default KeepMaskIcon;
