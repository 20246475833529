import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fflex.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xb2piMnJuMCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci5fMW9qYjJybjEgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLl8xb2piMnJuMiB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xb2piMnJuMyB7CiAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjQgewogIHdpZHRoOiAxMDAlOwogIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7Cn0KLl8xb2piMnJuNSB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xb2piMnJuNiB7CiAgaGVpZ2h0OiAxMDAlOwp9Ci5fMW9qYjJybjcgewogIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjggewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouXzFvamIycm45IHsKICBnYXA6IDhweDsKfQouXzFvamIycm5hIHsKICB3aWR0aDogMTAwJTsKfQouXzFvamIycm5iIHsKICB3aWR0aDogMTAwJTsKICBoZWlnaHQ6IDEwMCU7Cn0KLl8xb2piMnJuYyB7CiAgZ2FwOiA1cHg7Cn0KLl8xb2piMnJuZCB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZSB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZiB7CiAgZ2FwOiAxMHB4Owp9Ci5fMW9qYjJybmcgewogIGdhcDogMTBweDsKfQouXzFvamIycm5oIHsKICBnYXA6IDE1cHg7Cn0KLl8xb2piMnJuaSB7CiAgZ2FwOiAyMHB4Owp9Ci5fMW9qYjJybmogewogIGdhcDogNXB4Owp9Ci5fMW9qYjJybmsgewogIGdhcDogMTBweDsKfQouXzFvamIycm5sIHsKICBnYXA6IDEwcHg7Cn0%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fbutton.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WWa46bMBSF%2F88qkEaVZqQ64g1DdtAfVaUuoHLAgBuCqSEhmap7L88U2ySxeHQUJRKO%2BTice2zfzQ8ti%2BI8JKry%2B0lRdoQGiAIKA3zMPcXKzttq9IBTUOKgiD1F7a9jhKO46AbKGBcI5Bn0kaekpKQwq4czGAQ4jUCCwmqm1tH6UdoC%2BuGQpAUI4QEnF0%2F5SgqifIdprnz5Vv%2BZ4BRdH6ltHGv79Odp02vXWu3Q30eUHNMA%2BCQh1FNOkL6A9gJEFF5Ao%2FO1Bo7MyCg%2BQHoBAUJZM6c1o3pedlZykuBgdPoB4vSVlePF5IRoI8o%2F0rx%2BUEZwWiC6ldOZk7DgmdAv8AnNgCa1eyxVlzBOsOWxub1xalvZHTmDPIYBKZshxai%2BtadAr35otIMv6ufmszE5eXOMvOqGdM9j53h5u%2B6GhJsFgkl%2F6%2F%2B00phjZSNa9NGY5WMDFYNuLrKWWZvvL%2BTh3KGQFVaxucoqtiQsgwmixUcEz5pjY6taTJ41y8eWKlbHXiR6nNP3s8dMHkpZIXz2KuFzJEwLYBpVXcUHpM%2BZ42MnW4yfM8vJDisWyG14fZdjdO%2FeEZ7DMLy2STl%2BrxotzWAaqmqg9sbtLBM0POta%2FRHbqX%2BGXxs%2Fs%2Fe9KwOrc3Y4xcC%2FNbSuybTUT1zbqaudG71oUx10jZ0d7mCo7OZZKqsdDp9jc1DDFKGmBHTXQK9lMOt0cmjdnYb2h3o11%2BEE2yLVkqAGQ6pjLQNFQ6ih8wXTp1FDxgCD02qOVEwmBtEq1HgVKh5SdZf3dSL1J7MQTC4CI2mVge5XkZqsQj2sQk2H1DeJ%2FcWQgBImV6a7DDVjqRIblgz1F0O1F1oDdBVqzkRAOGImUgtGq8zuKnMQHNnmYOJJdVqEUjaUkbtGT%2BdblPMilMsilPdblFsr2ZaAauoDqmVMoWqP3lgOoy%2BDMR68ozbNOlPAugz2%2FobNYv8CHi6SSuQUAAA%3D%22%7D"
export var stylePopperColorCancelButton = '_1pghsfoq _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var stylePopperColorOkButton = '_1pghsfor _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var stylePopperTaskTrayGalleryButton = '_1pghsfot _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var stylePopperUserLogoutButton = '_1pghsfos _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledAdminAddUserButton = '_1pghsfob _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledAdminDetailBorderButton = '_1pghsfoa _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledCalendarClearBorderButton = '_1pghsfod _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledCalendarSearchButton = '_1pghsfoc _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledCancelRepeatGenButton = '_1pghsfop _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo4';
export var styledContactTallyButton = '_1pghsfo8 _1ojb2rnf _1ojb2rn0';
export var styledDialogAddUserButton = '_1pghsfov _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledDialogAddUserCancelButton = '_1pghsfou _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledDialogConfirmAbortionButton = '_1pghsfoz _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledDialogDeleteUserBorderButton = '_1pghsfow _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo6';
export var styledDialogDeleteUserButton = '_1pghsfox _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo5';
export var styledDialogErrorOkButton = '_1pghsfo10 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo7';
export var styledDialogProgressBackgroundButton = '_1pghsfo13 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledDialogRepeatGenCancelButton = '_1pghsfo11 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo6';
export var styledDialogRepeatGenOkButton = '_1pghsfo12 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo5';
export var styledDialogSendInvitationButton = '_1pghsfoy _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledDialogUploadEndButton = '_1pghsfo14 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledDownloaderCheckAllButton = '_1pghsfon _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledDownloaderUnCheckAllButton = '_1pghsfoo _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledFunctionClearButton = '_1pghsfog _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledFunctionCompleteButton = '_1pghsfom _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledFunctionDownloadButton = '_1pghsfok _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledFunctionGenerateButton = '_1pghsfoh _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledFunctionNextButton = '_1pghsfoi _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledFunctionPreviewButton = '_1pghsfof _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledFunctionRepeatButton = '_1pghsfol _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo3';
export var styledHomeBorderButton = '_1pghsfo9 _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo1';
export var styledNumberPlusMinusButton = '_1pghsfoj _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';
export var styledUploadFileSelectButton = '_1pghsfoe _1ojb2rn5 _1ojb2rn0 _1pghsfo0 _1pghsfo2';