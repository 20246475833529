import { Box } from '@/components/atoms/Box';
import { FadeIn } from '@/components/molecules/FadeIn';
import { StyledFlexCenterExpanded } from '@/components/styled';
import { Skelton } from '@/features/components/atoms/Skelton';
import { GlobalNavigator } from '@/features/components/organisms/GlobalNavigator';
import { StyledScrollContainer } from '@/features/components/styled/navigator';
import { StyledTable } from '@/features/components/styled/table';
import { FeaturesSingleLayoutWithScroll } from '@/features/components/templates/Layout/FeaturesSingleLayoutWithScroll';
import { useTasks, useTasksHandlers, useTasksParam } from '@/hooks/tasks';
import { Table } from '@mui/material';
import { memo } from 'react';
import { Body } from './Body';
import { Head } from './Head';
import { Search } from './Search';

export const TaskList = memo((): JSX.Element => {
  const {
    tasksParam,
    updateTasksParamObject,
    addUserId,
    removeUserId,
    tasksOrderBy,
    handleClickOrderBy,
  } = useTasksParam({});

  const { isToggledUserId, handelChangeLimit, handleToggleUserId } =
    useTasksHandlers({
      updateTasksParamObject,
      addUserId,
      removeUserId,
    });

  const {
    apiResponse,
    isLoading,
    count,
    page,
    scrollBoxRef,
    handleChangePage,
    isAutoReload,
  } = useTasks({
    tasksParam,
    updateTasksParamObject,
    hasAutoReload: true,
  });

  return (
    <>
      <FeaturesSingleLayoutWithScroll>
        <Search
          updateTasksParamObject={updateTasksParamObject}
          isToggledUserId={isToggledUserId}
          handleToggleUserId={handleToggleUserId}
        />
        <StyledScrollContainer ref={scrollBoxRef}>
          {!isAutoReload && isLoading && (
            <Box>
              <Skelton mode="taskList" />
            </Box>
          )}
          {(isAutoReload || !isLoading) && apiResponse && (
            <FadeIn duration={0.2}>
              {apiResponse.data.length <= 0 ? (
                <StyledFlexCenterExpanded>
                  データがありません
                </StyledFlexCenterExpanded>
              ) : (
                <StyledTable>
                  <Table>
                    <Head
                      tasksOrderBy={tasksOrderBy}
                      handleClickOrderBy={handleClickOrderBy}
                    />
                    <Body apiResponse={apiResponse} />
                  </Table>
                </StyledTable>
              )}
            </FadeIn>
          )}
        </StyledScrollContainer>
        <GlobalNavigator
          isLoading={isLoading}
          limit={tasksParam.limit}
          count={count}
          page={page}
          handelChangeLimit={handelChangeLimit}
          handleChangePage={handleChangePage}
        />
      </FeaturesSingleLayoutWithScroll>
    </>
  );
});
