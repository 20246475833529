import {
  FeatureBatchOriginalImage,
  FeatureData,
  ImageSize,
} from '@/contexts/FeaturesContext/types';
import { useImageSize } from '@/hooks/utils/useImageSize';
import { useEffect, useState } from 'react';

type Props = {
  featureData: FeatureData | undefined;
  currentNumber: number;
};

export const useGetImageStatus = ({ featureData, currentNumber }: Props) => {
  const [selectedOriginalImage, setSelectedOriginalImage] =
    useState<FeatureBatchOriginalImage>();
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [selectedImageFileName, setSelectedImageFileName] =
    useState<string>('');
  const { getImageSize } = useImageSize();

  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
    bytes: 0,
  });

  useEffect(() => {
    if (!featureData) return;
    if (featureData?.batch[currentNumber]?.originalImages.length > 0) {
      const selectedImage = featureData?.batch[
        currentNumber
      ]?.originalImages.find((image) => image.isSelected);
      if (!selectedImage?.url) return;
      setSelectedOriginalImage(selectedImage || undefined);
      setSelectedImageUrl(selectedImage.url);
      setSelectedImageFileName(selectedImage.fileName);
      if (!selectedImage.url) return;

      void (async () => {
        const res = await getImageSize(selectedImage.url);
        setImageSize(res);
      })();
    }
  }, [currentNumber, featureData, getImageSize]);

  return {
    selectedOriginalImage,
    imageSize,
    setImageSize,
    selectedImageUrl,
    setSelectedImageUrl,
    selectedImageFileName,
    setSelectedImageFileName,
  };
};
