import { Task, TaskResultImage } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import {
  StyledFlexGap5,
  StyledFont,
  StyledFont12,
  StyledFont16Bold,
  StyledGridGap8,
} from '@/components/styled';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider } from '@mui/material';
import { memo } from 'react';
import {
  StyledBoxPaddingBottom30,
  StyledBoxPaddingLeft5,
  StyledDetailContainer,
  StyledGridGap8PaddingBottom10,
  StyledImage,
  StyledImageBox,
} from './styled';

type Props = {
  detailData?: Task;
};

export const Detail = memo(({ detailData }: Props): JSX.Element => {
  const { convertToDateTime } = useConvertDate();

  return (
    <>
      {detailData && (
        <StyledDetailContainer>
          <StyledGridGap8>
            <StyledFont16Bold>生成</StyledFont16Bold>
            <Box>
              <StyledFont>{detailData.originalImageFileName}</StyledFont>
              <StyledFont>ファイル数: {detailData.nSample}</StyledFont>
            </Box>
          </StyledGridGap8>
          <Box>
            <Divider />
          </Box>
          <StyledGridGap8PaddingBottom10>
            <StyledFont16Bold>ダウンロード</StyledFont16Bold>
            <StyledGridGap8>
              {detailData.result.resultImages
                .filter((d: TaskResultImage) => d.downloadedAt)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((d: any, i: number) => (
                  <StyledFlexGap5 key={d.url}>
                    <StyledImageBox>
                      <StyledImage
                        src={`${d.url}&d=72x72`}
                        width={72}
                        height={72}
                        alt={`pict${i}`}
                      />
                    </StyledImageBox>
                    <StyledBoxPaddingLeft5>
                      <StyledFont12>
                        {convertToDateTime(d.createdAt)}
                      </StyledFont12>
                    </StyledBoxPaddingLeft5>
                  </StyledFlexGap5>
                ))}
            </StyledGridGap8>
          </StyledGridGap8PaddingBottom10>
          <StyledBoxPaddingBottom30>
            <Divider />
          </StyledBoxPaddingBottom30>
        </StyledDetailContainer>
      )}
    </>
  );
});
