import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect, useState } from 'react';

export type BatchConstant = {
  hasUploadRef: boolean /* 参照画像アップロードコンポーネントを必要とするか  */;
  hasRefAutoSam: boolean /* 参照画像・領域選択の自動生成on/offフラグ */;
  refAutoSamTitle: string /* 参照画像・領域選択・自動生成のサイドバー上のタイトル  */;
  hasMainImageSam: boolean /* メイン画像（調整画像）・領域選択を必要とするか */;
  mainImageSamTitle: string /* メイン画像（調整画像）・領域選択・自動生成のサイドバー上のタイトル  */;
};
/**
 * @see 各batch処理の定数を取得する
 */
export const useBatchConstant = () => {
  const { activeFeatureName } = useFeaturesContext({});

  const [batchConstant, setBatchConstant] = useState<BatchConstant | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!activeFeatureName) return;
    switch (activeFeatureName) {
      case 'colorTemperature':
        setBatchConstant({
          hasUploadRef: true,
          hasRefAutoSam: true,
          refAutoSamTitle: '自動生成',
          hasMainImageSam: false,
          mainImageSamTitle: '',
        });
        break;
      case 'backgroundLora':
        setBatchConstant({
          hasUploadRef: false,
          hasRefAutoSam: false,
          refAutoSamTitle: '',
          hasMainImageSam: true,
          mainImageSamTitle: '背景自動生成',
        });
        break;
      default:
        break;
    }
  }, [activeFeatureName]);

  return {
    batchConstant,
  };
};
