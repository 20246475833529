import { Box } from '@/components/atoms/Box';
import { StyledFlex } from '@/components/styled';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { useSwiperState } from '@/features/components/organisms/GlobalViewer/hooks/useSwiperState';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { memo, useCallback, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  StyledImage,
  StyledThumbnail,
  StyledThumbnailBox,
  StyledThumbnailContainer,
} from './styled';

type Props = {
  imageResults?: ImageResult[];
  label?: React.ReactNode;
  hasCheckIcon?: boolean;
  thumbnailBoxWidth?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swiperRef: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
  isBatch?: boolean;
};

export const SwiperThumbs = memo(
  ({
    imageResults,
    label,
    hasCheckIcon = false,
    thumbnailBoxWidth = 320,
    swiperRef,
    setSwiperRef,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
    isBatch = false,
  }: Props) => {
    const thumbnailBoxRef = useRef<HTMLDivElement>(null);
    const { handleChangeSlide } = useSwiperState({
      swiperRef,
      setSwiperRef,
      swiperIndex,
      setSwiperIndex,
    });

    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageResults?.[0]?.url,
      maxWidth: 72,
      maxHeight: 72,
    });

    const { checkIndex, findChecked, findDownloaded } = useCheckArray();

    const handleClick = useCallback(
      (i: number) => {
        handleChangeSlide(i);
        checkIndex(i);
      },
      [handleChangeSlide, checkIndex],
    );

    return (
      <Box
        style={{
          position: 'relative',
          height: 520,
          overflow: 'auto',
          overflowX: 'hidden',
          paddingTop: '2px',
        }}
      >
        <StyledFlex>
          <StyledThumbnailContainer
            ref={thumbnailBoxRef}
            width={thumbnailBoxWidth}
          >
            {label && <>{label}</>}
            <StyledThumbnailBox>
              {imageResults &&
                imageResults.length > 0 &&
                imageResults.map((imageResult: ImageResult, i: number) => (
                  <StyledThumbnail
                    key={`thumb-${imageResult.url}`.toString()}
                    onClick={() => {
                      handleClick(i);
                    }}
                    index={i}
                    swiperIndex={swiperIndex}
                    hasCheckIcon={hasCheckIcon}
                    isChecked={findChecked(i) || false}
                    isDownloaded={findDownloaded(i) || false}
                  >
                    {imageResult.url && (
                      <ImageSpinner
                        width={18}
                        height={18}
                        padding={0}
                        hasBackground={false}
                        src={`${imageResult.url}&d=72x72`}
                      >
                        <StyledImage
                          src={`${imageResult.url}&d=72x72`}
                          alt={`pict${i}`}
                          width={imageWidth}
                          height={imageHeight}
                          style={{
                            objectFit: 'contain',
                            backgroundImage:
                              repeatBackgroundImage && imageWidth && imageHeight
                                ? `url(${repeatBackgroundImage})`
                                : '',
                          }}
                        />
                      </ImageSpinner>
                    )}
                  </StyledThumbnail>
                ))}
            </StyledThumbnailBox>
          </StyledThumbnailContainer>
        </StyledFlex>
      </Box>
    );
  },
);
