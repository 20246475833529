import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight64, StyledGridEnd } from '@/components/styled';
import { StepExplain } from '@/features/components/atoms/StepExplain';
import { StyledExplainBox } from '@/features/components/atoms/StepExplain/styled';
import { BatchUploadingDialog } from '@/features/components/modals/BatchUploadingDialog';
import { ConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog';
import { useConfirmEraseData } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseData';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesBatchStepLayout } from '@/features/components/templates/Layout/FeaturesBatchStepLayout';
import { useDialog } from '@/hooks/global/useDialog';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { Divider } from '@mui/material';
import { memo } from 'react';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { SetTable } from './SetTable';

/**
 * @see バッチセッティングのメインコンポーネント
 */
export const BatchSetting = memo((): JSX.Element => {
  console.log('batchSetting');

  const { isOpenDialog } = useDialog();
  const { uploadingProgress } = useUploadingProgress();

  const {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    handleSubmitEraseDialog,
  } = useConfirmEraseData();

  return (
    <>
      <FeaturesBatchStepLayout>
        <StyledExplainBox>
          <StepExplain />
        </StyledExplainBox>
        <Box>
          <SetTable />
          <Divider />
          <StyledBoxHeight64 />
        </Box>
        <StyledFunctionBox>
          <Functions />
          <StyledGridEnd>
            <Buttons />
          </StyledGridEnd>
        </StyledFunctionBox>
      </FeaturesBatchStepLayout>
      <BatchUploadingDialog
        isOpen={isOpenDialog}
        loadingProgressPercent={uploadingProgress}
      />
      <ConfirmEraseDataDialog
        isOpenDialog={isOpenConfirmEraseDataDialog}
        handleCloseDialog={handleCloseConfirmEraseDataDialog}
        handleSubmit={handleSubmitEraseDialog}
      />
    </>
  );
});
