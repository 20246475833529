import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import {
  StyledBoxWidth10,
  StyledFlexGap10,
  StyledFont,
  StyledGrid,
} from '@/components/styled';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider } from '@mui/material';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const Date = memo(({ data }: Props): JSX.Element => {
  const { convertToDateTime } = useConvertDate();

  return (
    <Box>
      <StyledFlexGap10>
        <Divider orientation="vertical" flexItem />
        <StyledBoxWidth10 />
        <StyledGrid>
          <StyledFont>{convertToDateTime(data.createdAt)}</StyledFont>
          <StyledFont>{data.user.email}</StyledFont>
        </StyledGrid>
      </StyledFlexGap10>
    </Box>
  );
});
