import { useBackendApi } from '@/api';
import { User } from '@/api/schemas';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

const userAtom = atom<User | undefined>(undefined);

/**
 * JSDoc
 * @see 認証コンテキスト
 * @returns {Object}
 */
export const useAuthContext = () => {
  const [user, setUser] = useAtom(userAtom);
  const { getMe } = useBackendApi({});

  const updateUser = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const me = await getMe();
      setUser(me);
    } catch (error) {
      // TODO どうするか考える。ログアウト？
      console.error(error);
      setUser(undefined);
    }
  }, [getMe, setUser]);

  // Auth0のセッションが有効になった場合、APIにuserを取得しに行く。
  // セッションが無効になったら削除する。
  useEffect(() => {
    if (user) return;
    void updateUser();
  }, [updateUser, user]);

  return {
    user,
    setUser,
  };
};
