import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import { StyledBoxPaddingTop100, StyledFont } from '@/components/styled';
import { FEATURE_EN_NAMES_FROM_BACKEND } from '@/constants';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { GlobalDownloader } from '@/features/components/organisms/GlobalDownloader';
import { Buttons } from '@/features/components/steps/Download/Buttons';
import { Functions } from '@/features/components/steps/Download/Functions';
import { useUpdateDataApiFromTask } from '@/features/components/steps/Download/hooks/useUpdateDataApiFromTask';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useDownload } from '@/hooks/global/useDownload';
import { Divider } from '@mui/material';
import { memo } from 'react';
import {
  StyledDialogContainer,
  StyledDialogWidthBox,
  StyledHeaderBox,
} from './styled';

type Props = {
  taskId: string;
  isOpenDownloadDialog: boolean;
  handleCloseDownloadDialog: () => void;
};
export const DownloadDialog = memo(
  ({
    taskId,
    isOpenDownloadDialog,
    handleCloseDownloadDialog,
  }: Props): JSX.Element => {
    const { taskApiResponse } = useUpdateDataApiFromTask({
      taskId,
      isDialog: true,
    });

    const { downloadingName, handleDownloadWithApi, setFiles } = useDownload({
      taskApiResponse,
    });

    const targetFeatureName = () => {
      return FEATURE_EN_NAMES_FROM_BACKEND[
        taskApiResponse?.category as keyof typeof FEATURE_EN_NAMES_FROM_BACKEND
      ];
    };

    console.log('download dialog');

    return (
      <>
        {taskApiResponse && (
          <Dialog
            isOpenDialog={isOpenDownloadDialog}
            onClose={handleCloseDownloadDialog} // クローズさせない
            maxWidth="xl"
            dialogTitle=""
            hasCloseIcon
          >
            <StyledDialogContainer>
              <FeaturesModalDownloadLayout>
                <StyledDialogWidthBox>
                  <StyledHeaderBox>
                    <Box>
                      <TaskCellMode data={taskApiResponse} />
                    </Box>
                    <Box>
                      <TaskCellContent data={taskApiResponse} />
                    </Box>
                    <StyledFont>生成完了</StyledFont>
                  </StyledHeaderBox>
                  <Divider />
                </StyledDialogWidthBox>
                <StyledBoxPaddingTop100>
                  <GlobalDownloader
                    isGenerated
                    imageResults={taskApiResponse?.result.resultImages || []}
                    setFiles={setFiles}
                    repeatBackgroundImage={
                      taskApiResponse?.parameters?.noBackground
                        ? houndstooth.src
                        : ''
                    }
                  />
                </StyledBoxPaddingTop100>
                <StyledFunctionBox>
                  <StyledBoxPaddingTop100>
                    <Functions
                      isDialog
                      downloadingName={downloadingName}
                      targetFeatureName={targetFeatureName()}
                      handleDownloadWithApi={handleDownloadWithApi}
                      isNoBackground={
                        taskApiResponse?.parameters?.noBackground || false
                      }
                    />
                    <Box style={{ paddingTop: 210 }}>
                      <Buttons isDialog />
                    </Box>
                  </StyledBoxPaddingTop100>
                </StyledFunctionBox>
              </FeaturesModalDownloadLayout>
            </StyledDialogContainer>
          </Dialog>
        )}
      </>
    );
  },
);
