/* eslint-disable import/newline-after-import */
import type { SVGProps } from 'react';
import { memo } from 'react';
const UnCheckedCircle = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill="#fff" rx={12} />
      <circle
        cx={12}
        cy={12}
        r={9.5}
        stroke="#000"
        strokeOpacity={0.12}
        strokeWidth={2}
      />
    </svg>
  );
});
export default UnCheckedCircle;
