import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useCallback, useEffect } from 'react';

type Props = {
  setPostApiResponse: (postApiResponse: Task | undefined) => void;
  setGetApiResponse: (getApiResponse: Task | undefined) => void;
  handlePostPreviewApi: () => Promise<void>;
  handleCloseProgress: () => void;
};

/**
 * JSDoc
 * @see ハンドラー関数を返すカスタムフック
 * @see 生成の実行やストップ、クリア、リセットなどの機能を提供
 */
export const useHandlers = ({
  setPostApiResponse,
  setGetApiResponse,
  handlePostPreviewApi,
}: Props) => {
  const {
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    initialFeatureDataParamState,
    initialFeatureDataImageState,
    activeFeatureName,
    activateTargetStep,
  } = useFeaturesContext({});

  const handleStopGenerating = useCallback(() => {
    if (!featureData?.mainImage.combinedBase64) return;
    updateFeatureDataSingle('genStatus', {
      isStop: true,
      isGenerating: false,
      isRequesting: false,
      isGenerated: false,
    });
    setPostApiResponse(undefined);
    setGetApiResponse(undefined);

    if (activeFeatureName === 'white') {
      activateTargetStep('segmentation');
    }
  }, [
    featureData?.mainImage.combinedBase64,
    updateFeatureDataSingle,
    setPostApiResponse,
    setGetApiResponse,
    activeFeatureName,
    activateTargetStep,
  ]);

  const handleClear = useCallback(() => {
    if (!featureData?.mainImage.combinedBase64) return;
    updateFeatureDataSingle('param', initialFeatureDataParamState);
    updateFeatureData('refImage', initialFeatureDataImageState);
    updateFeatureDataSingle('genStatus', {
      isGenerated: false,
      isEditing: false,
    });
    updateFeatureDataSingle('genResult', {
      generatedImageResults: [],
      image: '',
    });
    setPostApiResponse(undefined);
    setGetApiResponse(undefined);
  }, [
    featureData?.mainImage.combinedBase64,
    updateFeatureDataSingle,
    initialFeatureDataParamState,
    updateFeatureData,
    initialFeatureDataImageState,
    setPostApiResponse,
    setGetApiResponse,
  ]);

  const handleResetAfterPreview = useCallback(() => {
    if (!featureData?.mainImage.combinedBase64) return;
    updateFeatureDataSingle('genStatus', {
      isGenerated: false,
    });
    updateFeatureDataSingle('genResult', {
      generatedImageResults: [],
      image: '',
    });
    setPostApiResponse(undefined);
    setGetApiResponse(undefined);
  }, [
    featureData?.mainImage.combinedBase64,
    updateFeatureDataSingle,
    setPostApiResponse,
    setGetApiResponse,
  ]);

  const handleGenerate = useCallback(() => {
    updateFeatureDataSingle('genStatus', {
      isEditing: false,
      isStop: false,
      isGenerating: true,
      isGenerated: false,
      isForceClear: false,
    });
    updateFeatureDataSingle('genResult', {
      generatedImageResults: [],
      image: '',
    });
    void handlePostPreviewApi();
    setPostApiResponse(undefined);
    setGetApiResponse(undefined);
  }, [
    updateFeatureDataSingle,
    handlePostPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
  ]);

  useEffect(() => {
    const replacer = (key: string, value: string) => {
      if (key === 'pickColor' || key === 'newColor') return undefined; // pickColor プロパティを除外

      return value;
    };

    const featureDataParamString = JSON.stringify(
      featureData?.single?.param,
      replacer,
    );
    const initialFeatureDataParamStateString = JSON.stringify(
      initialFeatureDataParamState,
      replacer,
    );

    if (featureDataParamString !== initialFeatureDataParamStateString) {
      updateFeatureDataSingle('genStatus', {
        isEditing: true,
      });

      return;
    }
    if (featureDataParamString === initialFeatureDataParamStateString) {
      updateFeatureDataSingle('genStatus', {
        isEditing: false,
      });
    }
  }, [
    featureData?.single?.param,
    updateFeatureDataSingle,
    initialFeatureDataParamState,
    setGetApiResponse,
    featureData?.single.genStatus.isGenerated,
  ]);

  useEffect(() => {
    if (!featureData?.single?.genStatus.isForceClear) return;
    handleResetAfterPreview();
  }, [
    featureData?.single?.genStatus.isForceClear,
    handleResetAfterPreview,
    updateFeatureData,
  ]);

  return {
    handleClear,
    handleResetAfterPreview,
    handleGenerate,
    handleStopGenerating,
  };
};
