import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { MultiUploadImages } from '@/components/icons/others';
import {
  StyledBoxHeight10,
  StyledFlexCenter,
  StyledGridGap5,
} from '@/components/styled';
import { useUploadImage } from '@/features/components/steps/BatchUpload/UploadSet/hooks/useUploadImage';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useBatchGetFiles } from '@/features/components/steps/BatchUpload/hooks/useGetBatchFiles';
import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  StyledDragDropZone,
  StyledDragDropZoneFontStyle,
  StyledUploadInterface,
} from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  disabled: boolean;
  warningFunction: WarningFunction;
};

export const UploadSetInterface = memo(
  ({
    targetId,
    targetNumber,
    disabled,
    warningFunction,
  }: Props): JSX.Element => {
    const { featureData, updateFeatureDataArray, handleChangeCurrentSet } =
      useBatchUpload();

    const { getFiles } = useBatchGetFiles({
      updateErrorAtGettingFiles: warningFunction.updateErrorAtGettingFiles,
    });
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
      getFilesFromEvent: (event) => getFiles(event),
    });

    useUploadImage({
      featureData,
      updateFeatureDataArray,
      updateWarningKeyValue: warningFunction.updateWarningKeyValue,
      resetWarnings: warningFunction.resetWarnings,
      acceptedFiles,
      targetNumber,
    });

    return (
      <>
        <StyledUploadInterface {...(disabled ? () => {} : getRootProps())}>
          <input
            {...(disabled
              ? { type: 'hidden' }
              : getInputProps({
                  className: `dropzone${targetNumber}-input`,
                }))}
          />
          <StyledDragDropZone
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              disabled && handleChangeCurrentSet(targetId);
              warningFunction.resetWarnings();
            }}
          >
            <StyledDragDropZoneFontStyle>
              <StyledGridGap5 style={{ justifyContent: 'center' }}>
                <StyledFlexCenter>
                  <MultiUploadImages />
                </StyledFlexCenter>
                調整する画像を
                <br />
                ドラック＆ドロップ
                <Box style={{ fontSize: 16, color: 'var(--color-gray-main)' }}>
                  複数の画像（上限20枚まで）
                </Box>
              </StyledGridGap5>
              <StyledBoxHeight10 />
              <Button style={{ fontSize: 15 }} disabled={disabled}>
                ファイルを選択
              </Button>
            </StyledDragDropZoneFontStyle>
          </StyledDragDropZone>
        </StyledUploadInterface>
      </>
    );
  },
);
