import { useBackendApi } from '@/api/hooks/useBackendApi';
import { Image } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useError } from '@/hooks/global/useError';
import { useDialog } from '@/hooks/local/useDialog';
import { memo, useCallback, useEffect, useState } from 'react';
import { useBatchUpload } from '../hooks/useBatchUpload';
import { UploadRefInterface } from './UploadRefInterface';

type Props = {
  targetId: string;
  targetNumber: number;
  warningFunction: WarningFunction;
};

/**
 * @see 参照画像をアップロード・表示するコンポーネント
 * @see とりあえずBatchUpload直下に置いているが適切かどうかは今後の機能追加次第
 */
export const UploadRef = memo(
  ({ targetId, targetNumber, warningFunction }: Props): JSX.Element => {
    const [refApiResponse, setRefApiResponse] = useState<Image>();
    const [isSubmit, setIsSubmit] = useState(false);
    const { updateFeatureDataArray, updateFeatureDataSingle } =
      useFeaturesContext({});
    const { featureData, currentNumber } = useBatchUpload();
    const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const { postImages } = useBackendApi({});
    const { logger } = useError();

    const handleSubmit = useCallback(() => {
      updateFeatureDataSingle('param', {
        newRef: featureData?.refImage.combinedBase64,
        currentRef: featureData?.refImage.combinedBase64,
      });
      handleCloseDialog();
      setIsSubmit(true);
    }, [
      updateFeatureDataSingle,
      featureData?.refImage.combinedBase64,
      handleCloseDialog,
    ]);

    /* handleSubmit後にImageSetのrefSamへコピーしている */
    useEffect(() => {
      if (!featureData) return;
      if (!featureData?.single?.param?.newRef) return;
      if (!refApiResponse) return;
      /* submitボタン押下時以外に呼ばせないようにしている */
      if (!isSubmit) return;

      // maskをアップロードしたらmaskImageIdとUrlをセットする
      // useStateで組むと長くなるのでletにしている
      let maskResponse: Image | null | undefined;
      void (async () => {
        try {
          maskResponse = await postImages({
            image: featureData.refImage.maskBase64,
            fileName: featureData.refImage.fileName,
          });
          const updateSet = featureData.batch.map((set, index: number) => {
            if (index === targetNumber) {
              return {
                ...set,
                isCurrent: true,
                uploadedAt: Date.now(),
                setName: featureData.refImage.fileName
                  .split('.')
                  .slice(0, -1)
                  .join('.'),
                refSam: featureData.refImage,
                refImage: {
                  ...set.refImage,
                  refImageId: refApiResponse.id,
                  refUrl: refApiResponse.originalImageUrl,
                  maskImageId: maskResponse?.id,
                  maskUrl: maskResponse?.originalImageUrl,
                },
              };
            }

            return { ...set, isCurrent: false };
          });
          updateFeatureDataArray('batch', updateSet);
          setIsSubmit(false);

          return maskResponse;
        } catch (error) {
          console.error(error);
          logger({ error });

          return null;
        }
      })();

      // 無限ループするから必要
      // featureData.single.paramで発火している
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetNumber, featureData?.single?.param, updateFeatureDataArray]);

    return (
      <>
        <UploadRefInterface
          handleOpenDialog={handleOpenDialog}
          targetId={targetId}
          targetNumber={targetNumber}
          disabled={targetNumber !== currentNumber}
          warningFunction={warningFunction}
          setRefApiResponse={setRefApiResponse}
        />
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle="選択範囲の調整"
          dialogExplain=""
          isBatch
        />
      </>
    );
  },
);
