import { Box } from '@/components/atoms/Box';
import { StyledFlexAlignStart, StyledGridGap10 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FileDetail } from '@/features/components/atoms/FileDetail';
import { GlobalSegmentation } from '@/features/components/organisms/GlobalSegmentation';
import { KeepMask } from '@/features/components/organisms/KeepMask';
import { StyledFunctionBox } from '@/features/components/styled/function';
import {
  StyledPreview,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { FeaturesStepLayout } from '@/features/components/templates/Layout/FeaturesStepLayout';
import { memo, useCallback } from 'react';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { useInitializeSamType } from './hooks/useInitializeSamType';
import { useStepDetector } from './hooks/useStepDetector';
/**
 * JSDoc
 * @see 後で実装が大変なのでSAMとオートSAMを切り替えを前提に実装
 * @see ルーペ機能はどうする？
 */
export const Segmentation = memo((): JSX.Element => {
  const field = 'mainImage';
  useInitializeSamType({ field });
  useStepDetector();
  const {
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    activateTargetStep,
    initialFeatureDataImageState,
    initialFeatureDataParamState,
    initialFeatureDataGenStatusState,
    initialFeatureDataGenResultState,
    activeFeatureName,
  } = useFeaturesContext({});

  const handleSubmit = () => {
    activateTargetStep('setting');
  };

  const handleResetImage = useCallback(() => {
    updateFeatureData('mainImage', initialFeatureDataImageState);
    updateFeatureData('refImage', initialFeatureDataImageState);
    updateFeatureDataSingle('param', initialFeatureDataParamState);
    updateFeatureDataSingle('genStatus', initialFeatureDataGenStatusState);
    updateFeatureDataSingle('genResult', initialFeatureDataGenResultState);
  }, [
    initialFeatureDataGenResultState,
    initialFeatureDataGenStatusState,
    initialFeatureDataImageState,
    initialFeatureDataParamState,
    updateFeatureData,
    updateFeatureDataSingle,
  ]);

  return (
    <>
      <FeaturesStepLayout>
        <StyledGridGap10>
          <StyledFlexAlignStart>
            <StyledPreview swiperIndex={0} maxIndex={0} width={660}>
              <StyledPreviewSlide>
                {featureData && (
                  <GlobalSegmentation
                    field={field}
                    data={featureData}
                    updateData={updateFeatureData}
                  />
                )}
              </StyledPreviewSlide>
            </StyledPreview>
            {activeFeatureName === 'item' && (
              <>
                <KeepMask />
              </>
            )}
          </StyledFlexAlignStart>
        </StyledGridGap10>
        <StyledFunctionBox>
          <Box>
            <Functions field={field} />
            <FileDetail field={field} onResetImage={handleResetImage} />
          </Box>
          <Buttons field={field} onSubmit={handleSubmit} />
        </StyledFunctionBox>
      </FeaturesStepLayout>
    </>
  );
});
