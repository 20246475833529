import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { useEffect, useState } from 'react';

/**
 * @see featureDataの状態から生成可能か否かを判定するカスタムフック
 * @see 色温度と背景Loraで条件が異なる
 */
export const useConditionDetector = () => {
  const { featureData, batchConstant } = useBatchUpload();
  const { activeFeatureName } = useFeaturesContext({});
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  // 色温度の条件
  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch[0]) return;
    if (!batchConstant) return;
    if (activeFeatureName !== 'colorTemperature') return;

    if (
      (batchConstant.hasUploadRef && !featureData.batch[0].refImage.refUrl) ||
      featureData.batch[0].originalImages.length <= 0
    ) {
      if (
        featureData.batch[1] &&
        featureData.batch[1].refImage.refUrl &&
        featureData.batch[1].originalImages.length > 0
      ) {
        setIsDisabledButton(false);

        return;
      }

      setIsDisabledButton(true);

      return;
    }

    if (!batchConstant.hasUploadRef && featureData.batch.length <= 0) {
      setIsDisabledButton(false);

      return;
    }

    setIsDisabledButton(false);
  }, [activeFeatureName, batchConstant, featureData, isDisabledButton]);

  // 背景Loraの条件
  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch[0]) return;
    if (!batchConstant) return;
    if (activeFeatureName !== 'backgroundLora') return;

    if (featureData.batch[0].originalImages.length <= 0) {
      if (
        featureData.batch[1] &&
        featureData.batch[1].originalImages.length > 0
      ) {
        setIsDisabledButton(false);

        return;
      }

      setIsDisabledButton(true);

      return;
    }

    if (featureData.batch.length <= 0) {
      setIsDisabledButton(false);

      return;
    }

    setIsDisabledButton(false);
  }, [activeFeatureName, batchConstant, featureData, isDisabledButton]);

  return { isDisabledButton };
};
