import { useBackendApi } from '@/api';
import { Image } from '@/api/schemas';
import { initialFeatureDataImageState } from '@/contexts/FeaturesContext/initial';
import { FeatureData } from '@/contexts/FeaturesContext/types';
import { useBatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { useEffect } from 'react';
import { FileResult } from './types';

type Props = {
  featureData: FeatureData | undefined;
  updateFeatureDataArray: (key: keyof FeatureData, value: unknown) => void;
  acceptedFiles: File[];
  targetNumber: number;
  handleCloseDialog: () => void;
  apiResponse: FileResult[];
  setApiResponse: (value: FileResult[]) => void;
};
/**
 * @see 調整画像のurlとidをfeaturesContext.batchに更新する
 */
export const useUpdateOriginalImages = ({
  featureData,
  updateFeatureDataArray,
  acceptedFiles,
  targetNumber,
  handleCloseDialog,
  apiResponse,
  setApiResponse,
}: Props) => {
  const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();
  const { setUploadingProgress } = useUploadingProgress();
  const { batchConstant } = useBatchConstant();
  const { postImages } = useBackendApi({});

  useEffect(() => {
    const fetchData = async () => {
      if (!featureData) return;
      if (!apiResponse.length) return;
      if (acceptedFiles.length <= 0) return;

      const allUpdate = await Promise.all(
        featureData.batch.map(async (set, index) => {
          if (index === targetNumber) {
            const targetImage = await Promise.all(
              apiResponse.map(async (res) => {
                let maskResponse: Image | null | undefined;
                try {
                  maskResponse = await postImages({
                    image: res.maskImageBase64,
                    fileName: res.main?.fileName || '',
                  });
                  console.log(maskResponse);
                } catch (error) {
                  console.error(error);
                }

                return {
                  fileName: res.main?.fileName || '',
                  url: res.main?.originalImageUrl || '',
                  inputImageId: res.main?.id || '',
                  maskImageId: maskResponse?.id || '',
                  keepImageId: '',
                  keepMaskImageId: '',
                  isApiError: false,
                  status: 0,
                  mainImage: {
                    ...initialFeatureDataImageState,
                    base64: res.base64 || '',
                    blobUrl: res.url || '',
                    maskBase64: res.maskImageBase64 || '',
                    combinedBase64: res.combinedImageBase64 || '',
                    combinedBase64Thumbnail120:
                      res.combinedImageBase64Thumbnail120 || '',
                    combinedBase64Thumbnail268:
                      res.combinedImageBase64Thumbnail268 || '',
                    combinedBase64Thumbnail512:
                      res.combinedImageBase64Thumbnail512 || '',
                    originalSize: res.originalSize,
                  },
                  isSelected: false,
                };
              }),
            );

            return {
              ...set,
              uploadedAt: Date.now(),
              originalImages: [...set.originalImages, ...targetImage],
              deleteImages: [],
            };
          }

          return set;
        }),
      );

      updateFeatureDataArray('batch', allUpdate);
      handleCloseDialog();
      setSnackbarParam({
        iconName: 'success',
        text: `画像をアップロードしました`,
        position: { placement: 'rightTop', x: 0, y: 0 },
      });
      setUploadingProgress(0);
      handleOpenSnackbar();
      setApiResponse([]);
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    acceptedFiles.length,
    handleOpenSnackbar,
    setSnackbarParam,
    updateFeatureDataArray,
    setApiResponse,
    apiResponse,
  ]);
  // hasRefがない場合は先頭の調整画像名をセット名として更新
  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch[targetNumber].originalImages.length) return;
    if (!featureData.batch[targetNumber].setName.includes('名称未設定')) return;
    if (!batchConstant) return;
    const updateSet = featureData.batch.map((set, index: number) => {
      if (index === targetNumber) {
        return {
          ...set,
          setName: featureData.batch[targetNumber].originalImages[0].fileName
            .split('.')
            .slice(0, -1)
            .join('.'),
        };
      }

      return set;
    });
    updateFeatureDataArray('batch', updateSet);
  }, [featureData, targetNumber, batchConstant, updateFeatureDataArray]);
};
