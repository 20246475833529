import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { FEATURE_EN_NAMES_FROM_BACKEND } from '@/constants';
import { useLoadingSpinner } from '@/hooks/global/useLoadingSpinner';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useGetFiles } from '@/hooks/utils/useGetFiles';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { useImageSize } from '@/hooks/utils/useImageSize';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
  initialFeatureDataApiState,
  initialFeatureDataGenResultState,
  initialFeatureDataGenStatusState,
  initialFeatureDataImageState,
  initialFeatureDataState,
  initialFeatureStates,
  initialInfoState,
} from './initial';
import {
  Feature,
  FeatureData,
  FeatureParam,
  FeatureSingle,
  FeatureStep,
} from './types';

const featuresAtom = atom<Feature[]>(initialFeatureStates);
const activeFeatureAtom = atom<Feature | undefined>(undefined);
const activeFeatureNameAtom = atom<string>('home');
const initialFeatureDataStepStatesAtom = atom<FeatureStep[]>([]);
const initialFeatureDataParamStateAtom = atom<FeatureParam | undefined>(
  undefined,
);

type Props = {
  initialFeatureName?:
    | 'background'
    | 'color'
    | 'item'
    | 'white'
    | 'colorTemperature'
    | 'backgroundLora';
  initialStepStates?: FeatureStep[];
  initialDataParamState?: FeatureParam;
};
/**
 * JSDoc
 * @see 生成機能のデータを保持するグローバルオブジェクト
 * @props {isInitial} initialFeatureName - 初期する機能名
 * @props {initialStepStates} initialStepStates - 初期ステップ
 * @props {initialDataParamState} initialDataParamState - 初期パラメータ
 * @see initialFeatureNameがある場合、初期ステップと初期パラメータをuseEffectにて設定
 * @returns {FeatureData} featuresDataContext - 生成機能のデータ
 * @returns {function} setFeaturesDataContext - 生成機能のデータを更新する関数
 * @returns {string} activeFeatureName - アクティブな機能名
 * @returns {FeatureData} activeFeature - アクティブな機能
 * @returns {FeatureMain} featureMain - アクティブな機能のメイン
 * @returns {FeatureSub} featureSub - アクティブな機能のサブ
 * @returns {FeatureStep[]} featureStep - アクティブな機能のステップ
 * @returns {FeatureData} featureData - アクティブな機能のデータ
 * @see 注意:オブジェクトを増やさない
 * @returns {function} updateFeature - アクティブな機能のfieldを丸ごと更新する関数
 * @returns {function} findActiveFeature - アクティブな機能を取得する関数
 * @returns {function} activateTargetFeature - ターゲット機能をアクティブにする関数
 * @returns {function} findActiveSub - アクティブなサブを取得する関数
 * @returns {function} activateTargetSub - ターゲットサブをアクティブにする関数
 * @returns {function} findActiveStep - アクティブなステップを取得する関数
 * @returns {function} activateTargetStep - ターゲットステップをアクティブにする関数
 * @returns {function} initializeFeatureDataContext - 生成機能のデータを初期化する関数
 * @returns {function} updateFeatureData - キーを指定して引数のオブジェクトを更新する関数
 * @returns {function} updateFeatureFromTaskApiResponse - タスクAPIのレスポンスから生成機能のデータを更新する関数
 * @see 注意:関数を増やさない
 * @returns {FeatureData} initialInfoState - 生成機能の情報の初期データ
 * @returns {FeatureStep[]} initialFeatureDataStepStates - 生成機能のステップの初期データ
 * @returns {FeatureData} initialFeatureDataState - 生成機能のデータの初期データ
 * @returns {FeatureData} initialFeatureDataImageState - 生成機能の画像の初期データ
 * @returns {FeatureData} initialFeatureDataGenStatusState - 生成機能の生成ステータスの初期データ
 * @returns {FeatureData} initialFeatureDataGenResultState - 生成機能の生成結果の初期データ
 * @returns {FeatureParam} initialFeatureDataParamState - 生成機能のパラメータの初期データ
 * @see イニシャルステートはuseFeaturesContextを介してexport
 * @see 各機能にて必要時に初期化ステートとして用いる
 */
export const useFeaturesContext = ({
  initialFeatureName,
  initialStepStates,
  initialDataParamState,
}: Props) => {
  const [featuresContext, setFeaturesContext] = useAtom(featuresAtom);
  const [activeFeature, setActiveFeature] = useAtom(activeFeatureAtom);
  const [activeFeatureName, setActiveFeatureName] = useAtom(
    activeFeatureNameAtom,
  );
  const [initialFeatureStepState, setFeaturesStepState] = useAtom(
    initialFeatureDataStepStatesAtom,
  );
  const [initialFeatureDataParamState, setFeaturesDataParamState] = useAtom(
    initialFeatureDataParamStateAtom,
  );

  /**
   * JSDoc
   * @see アクティブな生成機能のfieldを丸ごと更新する
   * @see 主にバックアップ用途に使用する
   * @param {FeatureData} data - バックアップデータ
   * @returns {void}
   */
  const updateFeature = useCallback(
    (field: keyof Feature, value: unknown): void => {
      setFeaturesContext((features) => {
        return features.map((feature) => {
          if (
            feature &&
            feature.main.isActive &&
            typeof value === 'object' &&
            value !== null
          ) {
            return {
              ...feature,
              [field]: value,
            };
          }

          return feature;
        });
      });
    },
    [setFeaturesContext],
  );

  /**
   * JSDoc
   * @see メイン機能をアクティベートする関数
   * @param {string} featureName - 機能名
   * @returns {void}
   */
  const activateTargetFeature = useCallback(
    (featureName: string) => {
      setFeaturesContext((features) =>
        features.map((feature: Feature) => ({
          ...feature,
          main: {
            ...feature.main,
            isActive: feature.main.name === featureName,
          },
        })),
      );
      setActiveFeatureName(featureName);
    },
    [setActiveFeatureName, setFeaturesContext],
  );

  /**
   * JSDoc
   * @see アクティブなメイン機能を取得する関数
   * @returns {Feature} activeFeature - アクティブな機能オブジェクト
   */
  const findActiveFeature = useCallback(
    () => featuresContext.find((d: Feature) => d.main.isActive),
    [featuresContext],
  );

  /**
   * JSDoc
   * @see アクティブなサブ機能を取得する関数
   * @returns {FeatureSub} activeSub - アクティブなサブ機能オブジェクト
   */
  const findActiveSub = useCallback(() => {
    const activeSub = activeFeature?.sub?.find((sub) => sub.isActive);

    return activeSub;
  }, [activeFeature?.sub]);

  /**
   * JSDoc
   * @see サブ機能をアクティベートする関数
   * @param {string} subName - サブ機能名
   * @returns {void}
   */
  const activateTargetSub = useCallback(
    (subName: string) => {
      setFeaturesContext((features) =>
        features.map((feature) => {
          if (feature.main.isActive) {
            return {
              ...feature,
              sub: feature.sub?.map((sub) => ({
                ...sub,
                isActive: sub.name === subName,
              })),
            };
          }

          return feature;
        }),
      );
    },
    [setFeaturesContext],
  );

  /**
   * JSDoc
   * @see アクティブなステップを取得する関数
   * @returns {FeatureStep} activeStep - アクティブなステップオブジェクト
   */
  const findActiveStep = useCallback(() => {
    const activeStep = activeFeature?.step?.find((step) => step.isActive);

    return activeStep;
  }, [activeFeature?.step]);

  /**
   * JSDoc
   * @see ステップをアクティベートする関数
   * @see データのcurrentStepにステップ名をセット
   * @see データバックアップから元データに戻す際にcurrentStepが活用出来る
   * @param {string} stepName - ステップ名
   * @returns {void}
   */
  const activateTargetStep = useCallback(
    (stepName: string) => {
      setFeaturesContext((features) =>
        features.map((feature) => {
          if (feature.main.isActive && feature.data) {
            return {
              ...feature,
              step: feature.step?.map((step) => ({
                ...step,
                isActive: step.name === stepName,
              })),
              data: {
                ...feature.data,
                currentStep: {
                  name: stepName,
                },
              },
            };
          }

          return feature;
        }),
      );
    },
    [setFeaturesContext],
  );

  /**
   * JSDoc
   * @see 生成機能のデータを更新する関数
   * @see 指定のfieldに対応したオブジェクトのみ更新できる点に注意
   * @see 配列であるstepは更新できない
   * @param {keyof FeaturesData} category - カテゴリー
   * @param {unknown} value - オブジェクト
   * @returns {void}
   */
  const updateFeatureData = useCallback(
    (field: keyof FeatureData, value: unknown): void => {
      setFeaturesContext((features) => {
        return features.map((feature) => {
          if (
            feature &&
            feature.main.isActive &&
            feature.data &&
            typeof value === 'object' &&
            value !== null
          ) {
            return {
              ...feature,
              data: {
                ...feature.data,
                [field]: {
                  ...feature.data[field],
                  ...value,
                },
              },
            };
          }

          return feature;
        });
      });
    },
    [setFeaturesContext],
  );

  /**
   * @see 単体のデータを更新する関数
   * @param {keyof FeatureSingle} field - フィールド名
   * @param {unknown} value - オブジェクト
   * @returns {void}
   */
  const updateFeatureDataSingle = useCallback(
    (field: keyof FeatureSingle, value: unknown): void => {
      setFeaturesContext((features) => {
        return features.map((feature) => {
          if (
            feature &&
            feature.main.isActive &&
            feature.data &&
            feature.data.single &&
            typeof value === 'object' &&
            value !== null
          ) {
            return {
              ...feature,
              data: {
                ...feature.data,
                single: {
                  ...feature.data.single,
                  [field]: {
                    ...feature.data.single[field],
                    ...value,
                  },
                },
              },
            };
          }

          return feature;
        });
      });
    },
    [setFeaturesContext],
  );

  /**
   * @see 配列のデータを更新する関数
   * @param {keyof FeatureData} field - フィールド名
   * @param {unknown} value - オブジェクト
   * @returns {void}
   */
  const updateFeatureDataArray = useCallback(
    (field: keyof FeatureData, value: unknown): void => {
      setFeaturesContext((features) => {
        return features.map((feature) => {
          if (
            feature &&
            feature.main.isActive &&
            feature.data &&
            typeof value === 'object' &&
            value !== null
          ) {
            return {
              ...feature,
              data: {
                ...feature.data,
                [field]: value,
              },
            };
          }

          return feature;
        });
      });
    },
    [setFeaturesContext],
  );

  /**
   * JSDoc
   * @see 生成機能のデータをApiResponseから復元する関数
   * @see 長いので別ファイルに切り出したいが、タスクの型が追加変更された場合に編集する必要があるため手元に置いておく
   * @param {Task} taskApiResponse - タスクAPIのレスポンス
   * @returns {void}
   */
  const { getImageBase64 } = useBase64();
  const { setIsOpenLoadingSpinner } = useLoadingSpinner();
  const { getImageMasking } = useImageMasking();
  const { getImageSize } = useImageSize();
  const { resetFiles } = useGetFiles({});
  const { getPresets } = useBackendApi({});

  const updateFeatureFromTaskApiResponse = useCallback(
    async (taskApiResponse: Task): Promise<void> => {
      /* ローディングをオン */
      setIsOpenLoadingSpinner(true);

      /* アクティブな機能を消去 */
      updateFeature('data', initialFeatureDataState);
      resetFiles();

      /* ターゲット機能名をアクティベート */
      const targetFeatureName =
        FEATURE_EN_NAMES_FROM_BACKEND[
          taskApiResponse.category as keyof typeof FEATURE_EN_NAMES_FROM_BACKEND
        ];
      activateTargetFeature(targetFeatureName);

      /* ターゲットステップをアクティベート */
      activateTargetStep('upload');

      const pramState = {
        targetType: '',
        pickColor: '',
        newColor: '',
        currentColor: '',
        newRef: '',
        currentRef: '',
        withShadow: 'on',
        blurLevel: 'none',
        generationCount: 1,
        format: 'png',
      };

      /* 画像の変換 */
      const mainBase64 = await getImageBase64(taskApiResponse.originalImageUrl);
      const mainMaskBase64 = taskApiResponse.maskImageUrl
        ? await getImageBase64(taskApiResponse.maskImageUrl)
        : '';

      const mainCombinedBase64 = (await getImageMasking({
        mainImageSource: taskApiResponse.originalImageUrl,
        maskImageSource: taskApiResponse.maskImageUrl,
      })) as unknown as string;

      const originalImageSize = await getImageSize(
        taskApiResponse.originalImageUrl,
      );

      const refBase64 = taskApiResponse.refImageUrl
        ? await getImageBase64(taskApiResponse.refImageUrl)
        : '';
      const refMaskBase64 = taskApiResponse.refMaskImageUrl
        ? await getImageBase64(taskApiResponse.refMaskImageUrl)
        : '';

      const mainKeepBaskBase64 = taskApiResponse.keepMaskImageUrl
        ? await getImageBase64(taskApiResponse.keepMaskImageUrl)
        : '';

      const mainKeepMaskCombinedBase64 = taskApiResponse.keepMaskImageUrl
        ? ((await getImageMasking({
            mainImageSource: taskApiResponse.originalImageUrl,
            maskImageSource: taskApiResponse.keepMaskImageUrl,
            color: '#0000ff',
          })) as unknown as string)
        : '';

      const refCombinedBase64 =
        taskApiResponse.refImageUrl && taskApiResponse.refMaskImageUrl
          ? ((await getImageMasking({
              mainImageSource: taskApiResponse.refImageUrl,
              maskImageSource: taskApiResponse.refMaskImageUrl,
            })) as unknown as string)
          : '';
      const refImageSize = await getImageSize(taskApiResponse.refImageUrl);

      const presetApiResponse = taskApiResponse.parameters?.presetId
        ? await getPresets()
        : null;
      let presetName = '';
      let presetUrl = '';
      if (presetApiResponse) {
        const foundPreset = presetApiResponse.data.find(
          (d) => d.id === taskApiResponse.parameters?.presetId,
        );
        if (foundPreset) {
          presetName = foundPreset.displayName;
          presetUrl = foundPreset.examples[0].url;
        }
      }

      /* ターゲット機能を初期化 */
      setFeaturesContext((features) => {
        return features.map((feature) => {
          if (feature.main.name === targetFeatureName && feature.data) {
            return {
              ...feature,
              data: {
                ...feature.data,
                info: {
                  featureName: activeFeatureName,
                  currentStep: 'upload',
                  createdAt: new Date().toISOString(),
                },
                mainImage: {
                  ...initialFeatureDataImageState,
                  fileName: taskApiResponse.originalImageFileName,
                  originalSize: originalImageSize,
                  base64: mainBase64,
                  maskBase64: mainMaskBase64,
                  combinedBase64: mainCombinedBase64,
                  keepBaskBase64: mainKeepBaskBase64,
                  keepMaskCombinedBase64: mainKeepMaskCombinedBase64,
                },
                refImage: {
                  ...initialFeatureDataImageState,
                  originalSize: refImageSize,
                  base64: refBase64,
                  maskBase64: refMaskBase64,
                  combinedBase64: refCombinedBase64,
                },
                single: {
                  param: {
                    ...pramState,
                    isNoBackground:
                      taskApiResponse?.parameters?.noBackground ?? false,
                    keepMaskImage: mainKeepMaskCombinedBase64,
                    targetType: taskApiResponse?.parameters?.color
                      ? 'color'
                      : taskApiResponse?.parameters?.presetId
                      ? 'preset'
                      : 'image',
                    newColor: taskApiResponse?.parameters?.color || '',
                    currentColor: taskApiResponse?.parameters?.color || '',
                    colorName: '',
                    newRef: refBase64 || '',
                    currentRef: refBase64 || '',
                    refImageName: '',
                    presetId: taskApiResponse?.parameters?.presetId || '',
                    presetName,
                    presetUrl,
                    withShadow: taskApiResponse?.parameters?.withShadow
                      ? 'on'
                      : 'off',
                    blurLevel: taskApiResponse?.parameters?.addBokeh || 'none',
                    generationCount: taskApiResponse?.nSample || 1,
                    format: taskApiResponse?.originalImageFormat || 'png',
                  },
                  genStatus: {
                    isEditing: false,
                    isGenerating: false,
                    isRequesting: false,
                    isStop: false,
                    isGenerated: true,
                    isForceClear: false,
                  },
                  genResult: {
                    taskId: taskApiResponse.id,
                    generatedImageIds: taskApiResponse.result.resultImages.map(
                      (image) => image.id,
                    ),
                    generatedImageResults: taskApiResponse.result.resultImages,
                    image: taskApiResponse.result.resultImages[0].url,
                  },
                },
                batch: [],
              },
            };
          }

          return feature;
        });
      });

      /*  白抜きの場合はダウンロードへ飛ばす */
      activateTargetStep(
        targetFeatureName === 'white' ? 'download' : 'setting',
      );

      /* 1秒後にローディングをオフ */
      setTimeout(() => {
        setIsOpenLoadingSpinner(false);
      }, 1000);
    },
    [
      setIsOpenLoadingSpinner,
      updateFeature,
      resetFiles,
      activateTargetFeature,
      activateTargetStep,
      getImageBase64,
      getImageMasking,
      getImageSize,
      getPresets,
      setFeaturesContext,
      activeFeatureName,
    ],
  );

  /**
   * JSDoc
   * @see アクティブな機能を取得するために発火するフック
   */
  useEffect(() => {
    setActiveFeature(findActiveFeature());
  }, [activeFeature, findActiveFeature, setActiveFeature]);

  /**
   * JSDoc
   * @see 機能トップにてステップとデータパラムの初期化に用いるフック
   * @see 初期化フラグがtrueの場合、初期ステップと初期パラメータを設定
   * @see initialStepStatesとinitialDataParamStateを出力用に保存
   * @see 注意：将来的に機能間をまたぐ場合は上記のような保存だと不都合が生じる
   * @returns {void}
   */
  useEffect(() => {
    if (!initialFeatureName || !initialStepStates || !initialDataParamState)
      return;

    setFeaturesContext((features) => {
      return features.map((feature) => {
        if (feature.main.name === initialFeatureName && feature.data) {
          return {
            ...feature,
            step: initialStepStates,
            data: {
              ...feature.data,
              info: {
                featureName: activeFeatureName,
                currentStep: 'upload',
                createdAt: new Date().toISOString(),
              },
              mainImage: initialFeatureDataImageState,
              refImage: initialFeatureDataImageState,
              single: {
                param: initialDataParamState,
                genStatus: initialFeatureDataGenStatusState,
                genResult: initialFeatureDataGenResultState,
              },
              batch: [],
              param: initialDataParamState, // 後に消す
              genStatus: initialFeatureDataGenStatusState, // 後に消す
              genResult: initialFeatureDataGenResultState, // 後に消す
            },
          };
        }

        return feature;
      });
    });
    setFeaturesStepState(initialStepStates);
    setFeaturesDataParamState(initialDataParamState);
  }, [
    initialDataParamState,
    initialStepStates,
    initialFeatureName,
    setFeaturesContext,
    setFeaturesDataParamState,
    setFeaturesStepState,
    activeFeatureName,
  ]);

  return {
    featuresContext,
    setFeaturesContext,
    activeFeatureName,
    activeFeature,
    featureMain: activeFeature?.main,
    featureSub: activeFeature?.sub,
    featureStep: activeFeature?.step,
    featureData: activeFeature?.data,
    updateFeature,
    findActiveFeature,
    activateTargetFeature,
    findActiveSub,
    activateTargetSub,
    findActiveStep,
    activateTargetStep,
    updateFeatureData,
    updateFeatureDataSingle,
    updateFeatureDataArray,
    updateFeatureFromTaskApiResponse,
    initialInfoState,
    initialFeatureStepState,
    initialFeatureDataState,
    initialFeatureDataApiState,
    initialFeatureDataImageState,
    initialFeatureDataGenStatusState,
    initialFeatureDataGenResultState,
    initialFeatureDataParamState,
  };
};
