import { FeatureData, ImageSize } from '@/contexts/FeaturesContext/types';
import { useCallback, useEffect } from 'react';

type Props = {
  featureData: FeatureData | undefined;
  currentNumber: number;
  updateFeatureDataArray: (key: keyof FeatureData, value: unknown) => void;
  setSelectedImageUrl: (url: string) => void;
  selectedImageFileName: string;
  setSelectedImageFileName: (fileName: string) => void;
  setImageSize: (size: ImageSize) => void;
};

export const useHandlers = ({
  featureData,
  currentNumber,
  updateFeatureDataArray,
  setSelectedImageUrl,
  selectedImageFileName,
  setSelectedImageFileName,
  setImageSize,
}: Props) => {
  const handleClearDeleteImages = () => {
    if (!featureData) return;
    const newSet = featureData.batch.map((batch, index) => {
      if (index === currentNumber) {
        return {
          ...batch,
          deleteImages: [],
        };
      }

      return batch;
    });

    updateFeatureDataArray('batch', newSet);
  };

  const handleDeleteSelectedImages = () => {
    if (!featureData) return;

    // fileNameにヒットした配列をoriginalNamesの配列から削除
    // originalImageには型がある

    const updatedOriginalImages = featureData.batch[
      currentNumber
    ].originalImages.filter(
      (image) =>
        !featureData.batch[currentNumber].deleteImages.includes(image.fileName),
    );

    const newSet = featureData.batch.map((batch, index) => {
      if (index === currentNumber) {
        return {
          ...batch,
          originalImages: updatedOriginalImages,
          deleteImages: [],
        };
      }

      return batch;
    });

    updateFeatureDataArray('batch', newSet);
  };

  const handleDeleteSelectedImage = useCallback(() => {
    if (!featureData) return;
    if (!selectedImageFileName) return;
    const updatedOriginalImages = featureData.batch[
      currentNumber
    ].originalImages.filter(
      (image) => image.fileName !== selectedImageFileName,
    );

    const updatedDeleteImages = featureData.batch[
      currentNumber
    ].deleteImages.filter((image) => image !== selectedImageFileName);
    const newSet = featureData.batch.map((batch, index) => {
      if (index === currentNumber) {
        return {
          ...batch,
          originalImages: updatedOriginalImages,
          deleteImages: updatedDeleteImages,
        };
      }

      return batch;
    });

    updateFeatureDataArray('batch', newSet);
    setSelectedImageUrl('');
    setSelectedImageFileName('');
    setImageSize({
      width: 0,
      height: 0,
      bytes: 0,
    });
  }, [
    currentNumber,
    featureData,
    selectedImageFileName,
    setImageSize,
    setSelectedImageFileName,
    setSelectedImageUrl,
    updateFeatureDataArray,
  ]);

  useEffect(() => {
    if (!featureData) return () => {};
    if (!featureData.batch[currentNumber]) return () => {};
    if (!featureData.batch[currentNumber].originalImages.length)
      return () => {};
    if (!selectedImageFileName) return () => {};

    const selectedImage = featureData.batch[currentNumber].originalImages.find(
      (image) => image.isSelected,
    );
    if (!selectedImage) return () => {};

    const handleClickOutside = (event: MouseEvent) => {
      if (
        !(event.target instanceof HTMLImageElement) &&
        !(event.target instanceof HTMLButtonElement)
      ) {
        setSelectedImageUrl('');
        setSelectedImageFileName('');
        setImageSize({
          width: 0,
          height: 0,
          bytes: 0,
        });

        if (!featureData) return;
        // if isSelectedがあれば
        const updateSet = featureData.batch.map((set) => {
          const newOriginalImages = set.originalImages.map((image) => ({
            ...image,
            isSelected: false, // すべての画像の isSelected を false に設定
          }));

          return {
            ...set,
            originalImages: newOriginalImages,
          };
        });
        updateFeatureDataArray('batch', updateSet);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [
    currentNumber,
    featureData,
    selectedImageFileName,
    setImageSize,
    setSelectedImageFileName,
    setSelectedImageUrl,
    updateFeatureDataArray,
  ]);

  return {
    handleClearDeleteImages,
    handleDeleteSelectedImages,
    handleDeleteSelectedImage,
  };
};
