import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Tooltip } from '@/components/atoms/Tooltip';
import { CheckedRef } from '@/components/icons/others';
import {
  StyledFlexCenter,
  StyledFlexGap10,
  StyledFlexGap20,
  StyledFontSx,
} from '@/components/styled';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { BatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import {
  StyledDividerBox,
  StyledTableRowContainer,
  StyledTableRowName,
  StyledTableRowNameEllipsis,
  StyledTableRowNameFlex,
} from '@/features/components/styled/batch';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider, IconButton } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { FaFolder } from 'react-icons/fa';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdMoreVert,
} from 'react-icons/md';

type Props = {
  set: FeatureBatch;
  handleChangeCurrentSet: (id: string) => void;
  checkUploadImageCondition: (id: string) => string | boolean;
  handleOpenSetNameDialog: () => void;
  isOpenAccordion: boolean;
  handleChangeAccordionState: (id: string) => void;
  handleOpenDeleteMenu: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
    setName: string,
  ) => void;
  batchConstant: BatchConstant | undefined;
};

export const Bar = memo(
  ({
    set,
    handleChangeCurrentSet,
    checkUploadImageCondition,
    handleOpenSetNameDialog,
    isOpenAccordion,
    handleChangeAccordionState,
    handleOpenDeleteMenu,
    batchConstant,
  }: Props): JSX.Element => {
    const { convertToDateTime } = useConvertDate();

    const folderColor = useMemo(
      () =>
        set.isCurrent
          ? checkUploadImageCondition(set.id)
            ? hexToRgba('#000', 0.56)
            : hexToRgba('#000', 0.38)
          : '#999',
      [set.id, set.isCurrent, checkUploadImageCondition],
    );

    const textColor = useMemo(
      () =>
        set.isCurrent
          ? checkUploadImageCondition(set.id)
            ? hexToRgba('#000', 1)
            : hexToRgba('#000', 0.38)
          : '#999',
      [set.id, set.isCurrent, checkUploadImageCondition],
    );

    return (
      <>
        <StyledTableRowContainer
          sx={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
          }}
          key={`set-${set.id}`}
          onClick={() => handleChangeCurrentSet(set.id)}
        >
          <StyledTableRowName>
            <StyledTableRowNameFlex sx={{ textColor }}>
              <StyledFlexGap10>
                <FaFolder size={24} color={folderColor} />
                <StyledTableRowNameEllipsis style={{ color: textColor }}>
                  <Tooltip title={set.setName} placement="bottom-start">
                    {set.setName}
                  </Tooltip>
                </StyledTableRowNameEllipsis>
              </StyledFlexGap10>
            </StyledTableRowNameFlex>
          </StyledTableRowName>
          <Box style={{ width: 100, maxWidth: 100 }}>
            {checkUploadImageCondition(set.id) ? (
              <Button
                mode="border"
                width={101}
                height={30}
                style={{ fontSize: 13 }}
                onClick={handleOpenSetNameDialog}
                disabled={!checkUploadImageCondition(set.id)}
              >
                セット名変更
              </Button>
            ) : (
              ''
            )}
          </Box>
          <StyledDividerBox>
            <Divider orientation="vertical" sx={{ backgroundColor: '#ddd' }} />
          </StyledDividerBox>
          <Box style={{ width: 200 }}>
            <StyledFontSx
              sx={{
                color: textColor,
              }}
            >
              {set.uploadedAt
                ? convertToDateTime(set.uploadedAt as unknown as string)
                : 'ー'}
            </StyledFontSx>
          </Box>
          <Box style={{ width: 150, textAlign: 'center' }}>
            <StyledFontSx
              sx={{
                color: textColor,
              }}
            >
              {set.originalImages.length || 'ー'}
            </StyledFontSx>
          </Box>
          {batchConstant?.hasUploadRef ? (
            <StyledFlexCenter style={{ width: 150 }}>
              {checkUploadImageCondition(set.id) ? <CheckedRef /> : ''}
            </StyledFlexCenter>
          ) : (
            <></>
          )}
          <Box style={{ width: 100, maxWidth: 100 }}>
            <StyledFlexGap20>
              {checkUploadImageCondition(set.id) ? (
                <IconButton
                  onClick={(e) => {
                    handleOpenDeleteMenu(e, set.id, set.setName);
                    e.stopPropagation();
                  }}
                  disabled={!checkUploadImageCondition(set.id)}
                >
                  <MdMoreVert size={20} />
                </IconButton>
              ) : (
                <></>
              )}

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeAccordionState(set.id);
                }}
              >
                {checkUploadImageCondition(set.id) ? (
                  <>
                    {isOpenAccordion ? (
                      <MdKeyboardArrowUp size={20} />
                    ) : (
                      <MdKeyboardArrowDown size={20} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </IconButton>
            </StyledFlexGap20>
          </Box>
        </StyledTableRowContainer>
      </>
    );
  },
);
