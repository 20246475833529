import { useBackendApi } from '@/api';
import { Task, TaskResultImage } from '@/api/schemas';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useError } from '@/hooks/global/useError';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  taskApiResponse: Task | undefined;
};
/**
 * @see ダウンロードを行うカスタムフック
 * @see 一枚生成から作られたためロジックが複雑 todo もう少しシンプルにしたい
 */
export const useDownload = ({ taskApiResponse }: Props) => {
  const { logger } = useError();
  const [apiResponse, setApiResponse] = useState<TaskResultImage | undefined>(
    undefined,
  );
  const { convertToDateTime } = useConvertDate();
  const [imageIdsArray, setImageIdsArray] = useState<string[] | undefined>([]);
  const [downloadingName, setDownloadingName] = useState('');
  const [isDownloaded, setIsDownloaded] = useState(false);

  const { getTaskImagesWithZip, getTaskImageWithoutZip } = useBackendApi({});
  const { checkArray, checkedCount, handleFindCheckedOne } = useCheckArray();
  const [files, setFiles] = useState<string[]>([]);

  /**
   * @see タスクレスポンスのresultImagesを全てダウンロードする
   */
  const handleDownloadAllWithApi = useCallback(
    async (format: string) => {
      if (!taskApiResponse) return;
      if (!taskApiResponse.result.resultImages.length) return;
      setDownloadingName(format);
      try {
        const param = {
          image_ids: taskApiResponse.result.resultImages.map(
            (image) => image.id,
          ),
          format,
        };

        setApiResponse(
          // eslint-disable-next-line @typescript-eslint/await-thenable
          await getTaskImagesWithZip(taskApiResponse.id, param),
        );
      } catch (error) {
        console.error(error);
        logger({ error });
        setDownloadingName('');
      }
      setDownloadingName('');
      setIsDownloaded(true);
    },
    [getTaskImagesWithZip, logger, taskApiResponse],
  );

  /**
   * @see チェックされた画像をダウンロードする
   * @see 一枚の場合はZipで固めないでダウンロードする
   */
  const handleDownloadWithApi = useCallback(
    async (format: string) => {
      if (!taskApiResponse) return;
      if (!imageIdsArray?.length) return;

      setDownloadingName(format);
      try {
        if (imageIdsArray.length === 1) {
          const param = {
            format,
          };

          // eslint-disable-next-line @typescript-eslint/await-thenable
          const tmp = await getTaskImageWithoutZip(
            taskApiResponse.id,
            imageIdsArray[0],
            param,
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          fetch(tmp.url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `image_${taskApiResponse.id}_${convertToDateTime(
                Date(),
              )}.${format}`; // 希望のファイル名を設定
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            });
        } else {
          const param = {
            image_ids: imageIdsArray,
            format,
          };

          setApiResponse(
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await getTaskImagesWithZip(taskApiResponse.id, param),
          );
        }
      } catch (error) {
        console.error(error);
        logger({ error });
        setDownloadingName('');
      }
      setDownloadingName('');
      setIsDownloaded(true);
    },
    [
      convertToDateTime,
      getTaskImageWithoutZip,
      getTaskImagesWithZip,
      imageIdsArray,
      logger,
      taskApiResponse,
    ],
  );

  useEffect(() => {
    if (!apiResponse) return;
    const link = document.createElement('a');
    link.href = apiResponse.url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    setDownloadingName('');
  }, [apiResponse]);

  useEffect(() => {
    if (!taskApiResponse) return;
    const newImageIdsArray: string[] = checkArray
      .map((d, i) => d && taskApiResponse.result.resultImages[i]?.id)
      .filter((imageId): imageId is string => Boolean(imageId));
    setImageIdsArray(newImageIdsArray);
  }, [checkArray, taskApiResponse]);

  return {
    downloadingName,
    isDownloaded,
    setIsDownloaded,
    handleDownloadWithApi,
    handleDownloadAllWithApi,
    checkArray,
    checkedCount,
    handleFindCheckedOne,
    files,
    setFiles,
  };
};
