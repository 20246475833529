import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { closeState, openState } from './initial';

type AccordionState = {
  id: string;
  isOpen: boolean;
};
export const accordionStatesAtom = atom<AccordionState[]>([]);
const isOpenAccordionAllAtom = atom<boolean>(true);
const isDisabledAccordionAllAtom = atom<boolean>(false);
const accordionAllButtonObjectAtom = atom<{
  display: string;
  icon: React.ReactNode;
}>(openState);

type Props = {
  isInitial?: boolean;
  currentNumber?: number;
};

/**
 * @see batchのアコーディオンを管理するグローバルステートを用いたカスタムフック
 * @see 汎用性はなくbatch処理のみに使用する
 * @see このフックを用いずともアコーディオンコンポーネントは使用出来る
 */

export const useAccordionContext = ({ isInitial, currentNumber }: Props) => {
  const [accordionStates, setAccordionStates] = useAtom(accordionStatesAtom);
  const [isOpenAccordionAll, setIsOpenAccordionAll] = useAtom(
    isOpenAccordionAllAtom,
  );
  const [isDisabledAccordionAll, setIsDisabledAccordionAll] = useAtom(
    isDisabledAccordionAllAtom,
  );
  const [accordionAllButtonObject, setAccordionAllButtonObject] = useAtom(
    accordionAllButtonObjectAtom,
  );
  const { featureData } = useFeaturesContext({});

  // isInitialがtrueの場合は初期化する
  useEffect(() => {
    if (!featureData) return;
    if (isInitial) {
      setAccordionStates(
        featureData.batch.map((batch) => ({ id: batch.id, isOpen: true })),
      );
      setIsOpenAccordionAll(true);
      setIsDisabledAccordionAll(false);
      setAccordionAllButtonObject(openState);
    }
  }, [
    isInitial,
    featureData,
    setAccordionStates,
    setIsOpenAccordionAll,
    setIsDisabledAccordionAll,
    setAccordionAllButtonObject,
  ]);

  // index===0でoriginalImagesが空の場合は新規追加と見なしてisOpenをtrueにする
  useEffect(() => {
    if (!featureData?.batch?.length) return;
    setAccordionStates((prev) =>
      featureData.batch.map((batch, index) => {
        if (index === 0 && batch.originalImages.length === 0) {
          return { id: batch.id, isOpen: true };
        }

        return {
          id: batch.id,
          isOpen: prev.some((state) => state.id === batch.id && state.isOpen),
        };
      }),
    );
  }, [currentNumber, featureData, setAccordionStates]);

  // アコーディオンの状態を変更する
  const handleChangeAccordionState = useCallback(
    (id: string) => {
      setAccordionStates((prev) =>
        prev.map((state) =>
          state.id === id ? { ...state, isOpen: !state.isOpen } : state,
        ),
      );
    },
    [setAccordionStates],
  );

  // アコーディオンの状態に応じてボタンの表示とAccordionAllの開閉を変更する
  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch.length) return;
    // 一番目が空セットの場合
    if (
      featureData?.batch[0].originalImages.length === 0 &&
      accordionStates.slice(1).every((state) => !state.isOpen)
    ) {
      setAccordionAllButtonObject(closeState);
      setIsOpenAccordionAll(false);
    } else if (
      featureData?.batch[0].originalImages.length === 0 &&
      accordionStates.slice(1).every((state) => state.isOpen)
    ) {
      setAccordionAllButtonObject(openState);
      setIsOpenAccordionAll(true);
    }
    // 全てのセットが画像を持つ場合
    else if (
      featureData.batch[0].originalImages.length > 0 &&
      accordionStates.every((state) => !state.isOpen)
    ) {
      setAccordionAllButtonObject(closeState);
      setIsOpenAccordionAll(false);
    } else if (
      featureData?.batch[0].originalImages.length > 0 &&
      accordionStates.every((state) => state.isOpen)
    ) {
      setAccordionAllButtonObject(openState);
      setIsOpenAccordionAll(true);
    }
  }, [
    accordionStates,
    featureData,
    setAccordionAllButtonObject,
    setIsOpenAccordionAll,
  ]);

  // AllAccordionButtonの表示を変更する
  const handleChangeAllAccordionDisplay = (isOpen: boolean) => {
    if (!isOpen) {
      setAccordionAllButtonObject(openState);
    } else {
      setAccordionAllButtonObject(closeState);
    }
  };

  // AllAccordionをクリックしたときの処理
  const handleClickAllAccordion = () => {
    if (!isOpenAccordionAll) {
      setAccordionAllButtonObject(openState);
      setIsOpenAccordionAll(true);
      setAccordionStates((prev) =>
        prev.map((state) => ({ ...state, isOpen: true })),
      );
    } else {
      setAccordionAllButtonObject(closeState);
      setIsOpenAccordionAll(false);
      if (!featureData?.batch?.length) return;
      setAccordionStates((prev) =>
        featureData.batch.map((batch, index) => {
          if (index === 0 && batch.originalImages.length === 0) {
            return { id: batch.id, isOpen: true };
          }

          return {
            id: batch.id,
            isOpen: false,
          };
        }),
      );
    }
  };

  // 一つ目の空セットの場合はdisableにする
  useEffect(() => {
    if (featureData?.batch?.length === 1 && currentNumber === 0) {
      setIsDisabledAccordionAll(true);
    } else {
      setIsDisabledAccordionAll(false);
    }
  }, [currentNumber, featureData, setIsDisabledAccordionAll]);

  return {
    accordionStates,
    handleChangeAccordionState,
    isOpenAccordionAll,
    setIsOpenAccordionAll,
    isDisabledAccordionAll,
    setIsDisabledAccordionAll,
    accordionAllButtonObject,
    handleChangeAllAccordionDisplay,
    handleClickAllAccordion,
  };
};
