import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useRequestBackground } from '@/features/components/steps/Segmentation/hooks/useRequestBackground';
import { useEffect } from 'react';

type Props = {
  field: ImageFields;
};

/**
 * JSDoc
 * @see セグメンテーションのSAMの初期化
 * @see samTypeが設定されていない場合は初期化
 * @see 背景と白抜きの場合はオートSAMを実行
 * @param {Props} { field }
 */
export const useInitializeSamType = ({ field }: Props) => {
  const { featureData, updateFeatureData, activeFeatureName } =
    useFeaturesContext({});

  const { handleRequestBackground } = useRequestBackground({
    field,
  });

  useEffect(
    () => {
      if (featureData?.[field].samType) return;
      updateFeatureData(field, {
        samType:
          activeFeatureName === 'background' ||
          activeFeatureName === 'white' ||
          activeFeatureName === 'backgroundLora'
            ? 'auto'
            : 'manual',
      });
      if (activeFeatureName === 'background' || activeFeatureName === 'white') {
        void handleRequestBackground();
      }
    },
    // 画像リセット時にsamTypeを書き換えてしまうため依存配列に何も入れない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
