import {
  StyledBoxHeight10,
  StyledFont,
  StyledFontBold,
  StyledGridAlignStart,
} from '@/components/styled';
import { StylePopperUserLogoutButton } from '@/components/styled/button';
import { useAuthContext } from '@/contexts/AuthContext';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';
import { StyledPopperFlex, StyledPopperGrid } from '../../styled';
/**
 * JSDoc
 * @see ユーザー情報ポッパー
 * @returns {JSX.Element}
 */
export const UserInfoPopper = memo((): JSX.Element => {
  const router = useRouter();
  const { user, setUser } = useAuthContext();

  const handleLogout = useCallback(() => {
    setUser(undefined);
    void router.push('/api/auth/logout');
  }, [router, setUser]);

  return (
    <>
      <StyledPopperGrid>
        <StyledPopperFlex>
          <StyledGridAlignStart>
            <StyledFontBold>
              {user && user.email && <>{user.email}</>}
            </StyledFontBold>
            <StyledFont>
              {user && user.organization && (
                <>{user.organization?.displayName}</>
              )}
            </StyledFont>
            <StyledBoxHeight10 />
          </StyledGridAlignStart>
        </StyledPopperFlex>
        <StylePopperUserLogoutButton onClick={handleLogout}>
          ログアウト
        </StylePopperUserLogoutButton>
      </StyledPopperGrid>
    </>
  );
});
