import { Box } from '@/components/atoms/Box';
import {
  StyledBoxWidth3,
  StyledBoxWidth30,
  StyledBoxWidth8,
  StyledFlexGap10,
  StyledGridGap5,
} from '@/components/styled';
import {
  StyledTdFile,
  StyledTdFixed,
} from '@/features/Gallery/uniques/TaskList/Body/styled';
import { StyledTable } from '@/features/components/styled/table';
import { range } from '@/utils/range';
import {
  Divider,
  Table,
  TableBody,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { motion } from 'framer-motion';
import { memo } from 'react';
import {
  StyledCircle56x56,
  StyledSquare100x16,
  StyledSquare218x16,
  StyledSquare56x56,
  StyledSquare70x16,
  StyledTaskListSpacer,
} from '../styled';

export const TaskList = memo(() => {
  return (
    <Box>
      <StyledTaskListSpacer />
      <Divider />
      {range(1, 10).map((i) => (
        <Box key={`skelton-${i}`.toString()}>
          <motion.div
            initial={{ opacity: 0.4 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
              delay: 1,
            }}
          >
            <Box>
              <StyledTable>
                <Table>
                  <TableBody>
                    <Tr>
                      <StyledTdFile>
                        <StyledFlexGap10>
                          <StyledSquare56x56 />
                          <StyledGridGap5>
                            <StyledSquare70x16 />
                            <StyledSquare218x16 />
                          </StyledGridGap5>
                        </StyledFlexGap10>
                      </StyledTdFile>
                      <StyledTdFixed>
                        <StyledFlexGap10>
                          <StyledCircle56x56 />
                          <StyledGridGap5>
                            <StyledSquare70x16 />
                            <StyledSquare70x16 />
                          </StyledGridGap5>
                        </StyledFlexGap10>
                      </StyledTdFixed>
                      <StyledTdFixed>
                        <StyledFlexGap10>
                          <StyledBoxWidth3 />
                          <Divider orientation="vertical" flexItem />
                          <StyledBoxWidth8 />
                          <StyledSquare56x56 />
                          <StyledBoxWidth3 />
                          <StyledGridGap5>
                            <StyledSquare70x16 />
                          </StyledGridGap5>
                        </StyledFlexGap10>
                      </StyledTdFixed>
                      <Td>
                        <StyledFlexGap10>
                          <StyledBoxWidth30 />
                          <StyledGridGap5>
                            <StyledSquare100x16 />
                            <StyledSquare100x16 />
                          </StyledGridGap5>
                        </StyledFlexGap10>
                      </Td>
                      <Td>
                        <StyledSquare100x16 />
                      </Td>
                    </Tr>
                  </TableBody>
                </Table>
              </StyledTable>
            </Box>
          </motion.div>
        </Box>
      ))}
    </Box>
  );
});
