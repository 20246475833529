/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@/components/atoms/Box';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { useSwiperState } from '@/features/components/organisms/GlobalViewer/hooks/useSwiperState';
import {
  StyledPreview,
  StyledPreviewBox,
  StyledPreviewImage,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { memo } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Thumbs } from 'swiper/modules';
import { StyledSwiperContainer } from './styled';

type Props = {
  imageResults: ImageResult[];
  swiperRef: any;
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
  taskType?: string;
};

/**
 * JSDoc
 * @see 写真スワイパーの汎用コンポーネント
 * @see プレビュー等で使用
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Swiper = memo(
  ({
    imageResults,
    swiperRef,
    setSwiperRef,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
    taskType,
  }: Props) => {
    console.log(taskType);
    const { handleGetActiveSwiperIndex } = useSwiperState({
      swiperRef,
      setSwiperRef,
      swiperIndex,
      setSwiperIndex,
    });
    /**
     * @see 2024-06-08に追加
     * 現状色温度の結果には参照画像があるため、スワイパーの幅は512pxにする
     * 以降のバッチに参照画像がないモードもあるので、その場合の幅は660pxにする
     * 将来的にもう少し簡潔な書き方をする
     */
    const isMini = taskType === 'generate_color_correction';
    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageResults[0].url,
      maxWidth: !isMini ? 660 : 512,
      maxHeight: !isMini ? 600 : 600,
    });

    return (
      <StyledSwiperContainer>
        <Box style={{ width: isMini ? 512 : 660 }}>
          <StyledPreviewBox id="slider">
            <StyledPreview
              onSwiper={(swiper) => {
                setSwiperRef(swiper);
              }}
              onSlideChangeTransitionEnd={handleGetActiveSwiperIndex}
              grabCursor
              centeredSlides
              slidesPerView="auto"
              spaceBetween={30}
              navigation
              modules={[Navigation, Thumbs]}
              swiperIndex={swiperIndex}
              maxIndex={imageResults.length - 1}
              className="mySwiper"
              width={isMini ? 512 : 660}
              style={{
                width: isMini ? 512 : 660,
              }}
            >
              <>
                {imageResults.map((imageResult: ImageResult, i: number) => (
                  <StyledPreviewSlide
                    key={`image-${imageResult.url}-${i}`.toString()}
                    style={{
                      width: isMini ? 512 : 600,
                    }}
                  >
                    <ImageSpinner src={imageResult.url}>
                      <StyledPreviewImage
                        src={imageResult.url}
                        width={imageWidth}
                        height={imageHeight}
                        style={{
                          objectFit: 'contain',
                          backgroundImage:
                            repeatBackgroundImage && imageWidth && imageHeight
                              ? `url(${repeatBackgroundImage})`
                              : '',
                        }}
                        quality={90}
                        alt={`pict${i}`}
                      />
                    </ImageSpinner>
                  </StyledPreviewSlide>
                ))}
              </>
            </StyledPreview>
          </StyledPreviewBox>
        </Box>
      </StyledSwiperContainer>
    );
  },
);
