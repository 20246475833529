import { useCallback, useEffect, useState } from 'react';
import { getImageSize } from 'react-image-size';

type Props = {
  imageUrl?: string;
  maxWidth: number;
  maxHeight: number;
};
export const useGetResizedSize = ({ imageUrl, maxWidth, maxHeight }: Props) => {
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const getImageDimension = useCallback(async () => {
    if (!imageUrl) return;
    const dimension = await getImageSize(imageUrl);

    let height;
    let width;
    let reductionRatio;

    const originalHeight = dimension.height;
    const originalWidth = dimension.width;
    const resizedHeight = originalHeight;
    const resizedWidth = originalWidth;

    if (resizedWidth / resizedHeight > maxWidth / maxHeight) {
      // 横が縦より大きい場合、横幅を基準にサイズ調整
      reductionRatio = maxWidth / resizedWidth;
      width = maxWidth;
      height = Math.ceil(resizedHeight * reductionRatio);
    } else {
      // 縦が横より大きいまたは等しい場合、縦幅を基準にサイズ調整
      reductionRatio = maxHeight / resizedHeight;
      height = maxHeight;
      width = Math.ceil(resizedWidth * reductionRatio);
    }

    setImageHeight(height);
    setImageWidth(width);
  }, [imageUrl, maxHeight, maxWidth]);

  useEffect(() => {
    if (!imageUrl) return;
    void (async () => {
      await getImageDimension();
    })();
  }, [getImageDimension, imageUrl]);

  return { imageWidth, imageHeight };
};
