import { useUploadImage } from '@/features/components/steps/BatchUpload/UploadSet/hooks/useUploadImage';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useBatchGetFiles } from '@/features/components/steps/BatchUpload/hooks/useGetBatchFiles';
import { useDropzone } from 'react-dropzone';
import { MdAdd } from 'react-icons/md';
import { StyledAddButtonBox, StyledButtonContent } from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  disabled: boolean;
  warningFunction: WarningFunction;
};

export const UploadAddInterface = ({
  targetId,
  targetNumber,
  disabled,
  warningFunction,
}: Props): JSX.Element => {
  const { featureData, updateFeatureDataArray, handleChangeCurrentSet } =
    useBatchUpload();

  const { getFiles } = useBatchGetFiles({
    updateErrorAtGettingFiles: warningFunction.updateErrorAtGettingFiles,
  });
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone({
      getFilesFromEvent: (event) => getFiles(event),
    });

  useUploadImage({
    featureData,
    updateFeatureDataArray,
    updateWarningKeyValue: warningFunction.updateWarningKeyValue,
    resetWarnings: warningFunction.resetWarnings,
    acceptedFiles,
    targetNumber,
  });

  return (
    <>
      <StyledButtonContent
        {...(disabled ? () => {} : getRootProps())}
        isDragActive={isDragActive}
      >
        <input
          {...(disabled
            ? { type: 'hidden' }
            : getInputProps({
                className: `upload-add-interface${featureData?.batch[targetNumber].id}`,
              }))}
        />
        <StyledAddButtonBox
          onClick={() => {
            if (disabled) {
              handleChangeCurrentSet(targetId);
            }
            warningFunction.resetWarnings();
          }}
        >
          <MdAdd
            size={40}
            color={
              disabled
                ? 'var(--color-primary-soft)'
                : 'var(--color-primary-main)'
            }
          />
        </StyledAddButtonBox>
      </StyledButtonContent>
    </>
  );
};
