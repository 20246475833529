import { useBackendApi } from '@/api/hooks/useBackendApi';
import { Presets } from '@/api/schemas';
import { useEffect, useState } from 'react';

export const useRequestPresets = () => {
  const [apiResponse, setApiResponse] = useState<Presets | undefined>(
    undefined,
  );
  const { getPresets } = useBackendApi({});

  useEffect(() => {
    if (apiResponse) return;
    const fetchPresets = async () => {
      try {
        const res = await getPresets();
        setApiResponse(res);
      } catch (error) {
        console.error(error);
      }
    };
    void fetchPresets();
  }, [apiResponse, getPresets]);

  return {
    apiResponse,
  };
};
