import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useEffect, useState } from 'react';

type Props = {
  taskId: string;
  isDialog: boolean;
};
export const useUpdateDataApiFromTask = ({ taskId, isDialog }: Props) => {
  const {
    featureData,
    updateFeature,
    updateFeatureData,
    updateFeatureFromTaskApiResponse,
  } = useFeaturesContext({});
  const { getTask } = useBackendApi({});
  const [taskApiResponse, setTaskApiResponse] = useState<Task | undefined>(
    undefined,
  );
  const [currentId, setCurrentId] = useState<string>('');

  useEffect(() => {
    if (taskId === currentId) return;
    void (async () => {
      setCurrentId(taskId);
      try {
        setTaskApiResponse(await getTask(taskId, {}));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [getTask, taskId, taskApiResponse, currentId]);

  useEffect(() => {
    if (isDialog) return;
    if (!taskApiResponse) return;
    if (taskApiResponse.id !== featureData?.single?.genResult.taskId) {
      void updateFeatureFromTaskApiResponse(taskApiResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskApiResponse, updateFeature, updateFeatureData]);

  return { taskApiResponse };
};
