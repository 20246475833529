import { StyledGridGap10 } from '@/components/styled';
import { FeatureBatchOriginalImage } from '@/contexts/FeaturesContext/types';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { useDialog } from '@/hooks/local/useDialog';
import { memo } from 'react';
import { ThumbnailWithCheck } from './ThumbnailWithCheck';
import { UploadAddInterface } from './UploadAddInterface';
import { UploadSetInterface } from './UploadSetInterface';
import { useUploadSetHandlers } from './hooks/useUploadSetHandlers';
import { StyledImageSetContainer } from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
};

/**
 * @see 調整画像をアップロード・表示するコンポーネント
 * @see 色温度と背景Loraで挙動が異なる
 * @see 色温度は領域選択なし
 * @see 背景Loraは領域選択あり
 * @see 背景Loraの場合は領域選択語の合成画像を表示する
 */
export const UploadSet = memo(
  ({
    targetId,
    targetNumber,
    setFunctionViewType,
    warningFunction,
  }: Props): JSX.Element => {
    const { featureData, currentNumber } = useBatchUpload();
    const { handleSelectImage, handleCheckDeleteImages } = useUploadSetHandlers(
      { targetNumber, setFunctionViewType },
    );
    const { isOpenDialog, handleCloseDialog } = useDialog();
    const handleSubmit = () => {
      console.log('submit');
    };

    return (
      <>
        {featureData &&
        featureData.batch.length > 0 &&
        featureData?.batch[targetNumber]?.originalImages.length > 0 ? (
          <StyledGridGap10>
            <StyledImageSetContainer>
              <>
                <UploadAddInterface
                  key={`upload-add-interface${featureData.batch[targetNumber].id}`}
                  targetId={targetId}
                  targetNumber={targetNumber}
                  disabled={
                    targetNumber !== currentNumber ||
                    featureData.batch[targetNumber].originalImages.length >= 20
                  }
                  warningFunction={warningFunction}
                />
                {featureData?.batch[targetNumber]?.originalImages.map(
                  (image: FeatureBatchOriginalImage, index: number) => (
                    <ThumbnailWithCheck
                      key={`thumbnail-with-check-${index}`.toString()}
                      image={image}
                      index={index}
                      handleCheckDeleteImages={handleCheckDeleteImages}
                      handleSelectImage={handleSelectImage}
                      warningFunction={warningFunction}
                      featureData={featureData}
                      targetNumber={targetNumber}
                    />
                  ),
                )}
              </>
            </StyledImageSetContainer>
          </StyledGridGap10>
        ) : (
          <UploadSetInterface
            key={`upload-set-interface${featureData?.batch[targetNumber].id}`}
            targetId={targetId}
            targetNumber={targetNumber}
            disabled={targetNumber !== currentNumber}
            warningFunction={warningFunction}
          />
        )}
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle="選択範囲の調整"
          dialogExplain=""
          isBatch
        />
      </>
    );
  },
);
