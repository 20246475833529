import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

/**
 * JSDoc
 * @see axiosのPOSTリクエストを行う
 * @see 型ガードを介したくない場合はこちらを使用する
 * @see 新機能ではこちらを使用するのが無難
 * @see 機能が安定したらaxiosPostWithZodを使用する
 * @param apiName - APIのエンドポイント名
 * @param payload - リクエストボディ
 * @returns レスポンスデータ
 * @see camelcaseKeysを使用してキャメルケースに変換している
 */
export const axiosPost = async (apiName: string, payload: unknown) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const snakedPayload =
    payload &&
    snakecaseKeys(payload as Record<string, unknown>, { deep: true });

  const response = await axios.post(`/api/backend/${apiName}`, snakedPayload, {
    headers,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = camelcaseKeys(response.data, { deep: true });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
};

/**
 * JSDoc
 * @see axiosのPOSTリクエストを行う
 * @see Zodを用いて型ガードを行う場合はこちらを使用する
 * @param apiName - APIのエンドポイント名
 * @param schema - レスポンスデータの検証スキーマ
 * @param payload - リクエストボディ
 * @returns レスポンスデータ
 * @see camelcaseKeysを使用してキャメルケースに変換している
 */
export const axiosPostWithZod = async <T>(
  apiName: string,
  schema: z.ZodSchema<T>,
  payload: unknown,
) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const snakedPayload =
    payload &&
    snakecaseKeys(payload as Record<string, unknown>, { deep: true });

  const response = await axios.post(`/api/backend/${apiName}`, snakedPayload, {
    headers,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedData = camelcaseKeys(response.data, { deep: true });
  let data;
  try {
    // データ検証を試みる
    data = schema.parse(parsedData);
  } catch (err) {
    if (err instanceof Error) {
      console.log(err);
    }
  }

  return data;
};
