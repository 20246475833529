import { StyledGridGap10 } from '@/components/styled';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { BatchWarnings } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { StyledFunctionPadding } from '@/features/components/styled/function';
import { memo } from 'react';
import { DeleteStatus } from './DeleteStatus';
import { ImageStatus } from './ImageStatus';
import { WarningsDisplay } from './WarningsDisplay';
import { useGetImageStatus } from './hooks/useGetImageStatus';
import { useHandlers } from './hooks/useHandlers';

type Props = {
  functionViewType: FunctionViewType;
  warningsData: BatchWarnings;
};

/**
 * @see アップロードした画像の表示・削除・チェックした画像の削除を行うコンポーネント
 */

export const Functions = memo(
  ({ functionViewType, warningsData }: Props): JSX.Element => {
    const { featureData, currentNumber, updateFeatureDataArray } =
      useBatchUpload();
    const {
      selectedOriginalImage,
      imageSize,
      setImageSize,
      selectedImageUrl,
      setSelectedImageUrl,
      selectedImageFileName,
      setSelectedImageFileName,
    } = useGetImageStatus({ featureData, currentNumber });

    const {
      handleClearDeleteImages,
      handleDeleteSelectedImages,
      handleDeleteSelectedImage,
    } = useHandlers({
      featureData,
      currentNumber,
      updateFeatureDataArray,
      setSelectedImageUrl,
      selectedImageFileName,
      setSelectedImageFileName,
      setImageSize,
    });

    return (
      <>
        <StyledFunctionPadding sx={{ padding: '-32px' }}>
          <StyledGridGap10>
            <>
              <WarningsDisplay warningsData={warningsData} />
              <DeleteStatus
                functionViewType={functionViewType}
                featureData={featureData}
                currentNumber={currentNumber}
                handleClearDeleteImages={handleClearDeleteImages}
                handleDeleteSelectedImages={handleDeleteSelectedImages}
              />
              <ImageStatus
                functionViewType={functionViewType}
                handleDeleteSelectedImage={handleDeleteSelectedImage}
                selectedImageUrl={selectedImageUrl}
                selectedImageFileName={selectedImageFileName}
                imageSize={imageSize}
                selectedOriginalImage={selectedOriginalImage}
                featureData={featureData}
                currentNumber={currentNumber}
              />
            </>
          </StyledGridGap10>
        </StyledFunctionPadding>
      </>
    );
  },
);
