/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';

type Props = {
  swiperRef: any;
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
};
export const useSwiperState = ({
  swiperRef,
  setSwiperRef,
  swiperIndex,
  setSwiperIndex,
}: Props) => {
  const handleGetActiveSwiperIndex = useCallback(
    (swiper: any) => {
      setSwiperIndex(swiper.realIndex);
    },
    [setSwiperIndex],
  );

  const handleChangeSlide = useCallback(
    (i: number) => {
      if (!swiperRef) return;
      swiperRef.slideToLoop(i);
      setSwiperIndex(i);
    },
    [setSwiperIndex, swiperRef],
  );

  useEffect(() => {
    if (!swiperRef) return;
    if (swiperIndex) return;
    swiperRef.slideToLoop(0);
    setSwiperIndex(0);
  }, [setSwiperIndex, swiperIndex, swiperRef]);

  return {
    swiperRef,
    setSwiperRef,
    handleGetActiveSwiperIndex,
    handleChangeSlide,
  };
};
