import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useBatchSetting } from '@/features/components/steps/BatchSetting/hooks/useBatchSetting';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useEffect } from 'react';

export const useFunctionsInitialization = () => {
  const { featureData, updateFeatureDataArray } = useFeaturesContext({});
  const { currentNumber } = useBatchSetting();
  const { collectiveSettingParam } = useBatchOptionalContext();

  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch[currentNumber]) return;
    if (!featureData.batch[currentNumber].mainParam) return;
    if (featureData.batch[currentNumber].mainParam.targetType) return;
    const updateSet = featureData?.batch.map((set, index: number) => {
      if (index === currentNumber) {
        return {
          ...set,
          uploadedAt: Date.now(),
          mainParam: {
            ...featureData.batch[index].mainParam,
            targetType: 'image',
          },
        };
      }

      return set;
    });
    updateFeatureDataArray('batch', updateSet);
  }, [
    collectiveSettingParam,
    currentNumber,
    featureData,
    updateFeatureDataArray,
  ]);
};
