import { useCallback, useState } from 'react';
import { DropEvent } from 'react-dropzone';
import { useValidateUploadImage } from './useValidateUploadImage';

type MyEvent = DropEvent | InputEvent;

type Props = {
  setErrorFiles?: (errorFiles: File[]) => void;
  setExceededFiles?: (exceededFiles: File[]) => void;
};

export const useGetFiles = ({ setErrorFiles, setExceededFiles }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const resetFiles = useCallback(() => {
    setFiles([]);
    setErrorFiles?.([]);
    setExceededFiles?.([]);
  }, [setErrorFiles, setExceededFiles]);

  const { validateImageFiles } = useValidateUploadImage();

  const getFiles = useCallback(
    async (event: MyEvent) => {
      const newFiles: File[] = [];
      const newErrorFiles: File[] = [];
      const newExceededFiles: File[] = [];
      setErrorFiles?.([]);
      setExceededFiles?.([]);

      const getFileFromHandle = async (fileHandle: FileSystemFileHandle) => {
        const file = await fileHandle.getFile();
        console.log('---file', file);

        return file;
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const getItemFile = (item: any): Promise<File> => {
        // eslint-disable-next-line no-promise-executor-return, @typescript-eslint/no-unsafe-return
        return new Promise((resolve) => item.file(resolve));
      };

      if (Array.isArray(event) && event[0] instanceof FileSystemFileHandle) {
        const handles = await Promise.all(event.map(getFileFromHandle));
        handles.forEach((file) => {
          validateImageFiles(file, newFiles, newExceededFiles, newErrorFiles);
        });
      } else if (
        event instanceof InputEvent &&
        event.target instanceof HTMLInputElement
      ) {
        const fileList = event.target.files;
        if (fileList) {
          Array.from(fileList).forEach((file) => {
            validateImageFiles(file, newFiles, newExceededFiles, newErrorFiles);
          });
        }
      } else if (event.type === 'drop') {
        const dataTransferItemsList = (event as DragEvent).dataTransfer?.items;
        if (!dataTransferItemsList) {
          return newFiles;
        }
        const itemEntries = Array.from(dataTransferItemsList).map((item) =>
          item.webkitGetAsEntry ? item.webkitGetAsEntry() : item,
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const traverseFileTree = async (item: any) => {
          if (item.isFile) {
            const file = await getItemFile(item);
            validateImageFiles(file, newFiles, newExceededFiles, newErrorFiles);
          } else if (item.isDirectory) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const dirReader = item.createReader();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const entries = await new Promise<any[]>((resolve) =>
              // eslint-disable-next-line no-promise-executor-return, @typescript-eslint/no-unsafe-return
              dirReader.readEntries(resolve),
            );
            await Promise.all(entries.map((entry) => traverseFileTree(entry)));
          }
        };

        await Promise.all(itemEntries.map((entry) => traverseFileTree(entry)));
      }
      setFiles(newFiles); // 処理が完了したら、newFilesでfiles状態を更新
      setErrorFiles?.(newErrorFiles);
      setExceededFiles?.(newExceededFiles);

      return newFiles;
    },
    [setErrorFiles, setExceededFiles, validateImageFiles],
  );

  return {
    files,
    getFiles,
    resetFiles,
  };
};
