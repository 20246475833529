import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { StyledFlexCenter, StyledGridGap25 } from '@/components/styled';
import { useConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseDataDialog';
import { memo, useCallback } from 'react';
import {
  styledVerticalAlignBottom,
  styledVerticalAlignNone,
} from '../styled/vanilla.css';
import { useRequest } from './hooks/useRequest';

type Props = {
  isDialog?: boolean;
  isAllTasksDone: boolean;
  deletedIds: string[];
};

export const BackgroundLoraButton = memo(
  ({ isDialog, isAllTasksDone, deletedIds }: Props): JSX.Element => {
    const { handleOpenConfirmEraseDataDialog, setTargetFeatureName } =
      useConfirmEraseDataDialog();

    const handleGoToTop = useCallback(() => {
      setTargetFeatureName('backgroundLora');
      handleOpenConfirmEraseDataDialog();
    }, [handleOpenConfirmEraseDataDialog, setTargetFeatureName]);

    const { hasRequestSet, handleRequestAll } = useRequest({ deletedIds });

    return (
      <>
        <StyledFlexCenter>
          <Box
            className={
              !isDialog ? styledVerticalAlignBottom : styledVerticalAlignNone
            }
          >
            <StyledGridGap25>
              {!isDialog && (
                <>
                  <Button
                    style={{ width: 280, fontSize: 15 }}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleRequestAll}
                    disabled={!isAllTasksDone || !hasRequestSet}
                  >
                    生成
                  </Button>
                  <Button
                    mode="border"
                    style={{ width: 280, fontSize: 15 }}
                    onClick={handleGoToTop}
                  >
                    複数背景生成トップへ
                  </Button>
                </>
              )}
              <Box />
            </StyledGridGap25>
          </Box>
        </StyledFlexCenter>
      </>
    );
  },
);
