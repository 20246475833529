import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledFont, StyledGridGap5 } from '@/components/styled';
import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import {
  StyledCircle,
  StyledCircleImage,
} from '@/features/Gallery/uniques/styled';
import hexToRgba from 'hex-to-rgba';
import { memo } from 'react';
import { StyedTaskContentContainer } from './styled';

type Props = {
  data: Task;
};
/**
 * JSDoc
 * @see タスクの指示内容を表示するコンポーネント
 * @see ギャラリーやモーダルダウンロードにて使用する汎用コンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const TaskCellContent = memo(({ data }: Props): JSX.Element => {
  const modeEn =
    TASK_TYPE_TO_EN_MODE_FROM_BACKEND[
      data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND
    ];

  return (
    <Box>
      <StyedTaskContentContainer>
        <Box>
          <>
            {data.parameters?.color && (
              <StyledCircle
                sx={{
                  backgroundColor: data.parameters.color,
                }}
              />
            )}
            {modeEn === 'white' && (
              <StyledCircle
                sx={{
                  backgroundColor: 'var(--color-gray-white)',
                }}
              />
            )}
            {data.refImageUrl &&
              modeEn !== 'white' &&
              !data.parameters?.color && (
                <>
                  <StyledCircleImage
                    src={`${data.refImageUrl}&d=56x56`}
                    width={56}
                    height={56}
                    alt={`${data.refImageUrl}`}
                  />
                </>
              )}
          </>
        </Box>
        <StyledGridGap5
          sx={{
            whiteSpace: 'nowrap',
            fontColor:
              data.result.status === 'IN_QUEUE' ||
              data.result.status === 'FAILED'
                ? hexToRgba('#000', 0.6)
                : 'var(--color-gray-main)',
          }}
        >
          <Box>
            <StyledFont>
              {modeEn === 'white' && '白抜き'}
              {modeEn !== 'white' && data.parameters?.color && 'カラー指定'}
              {modeEn !== 'white' && !data.parameters?.color && '画像指定'}
              {data.parameters?.noBackground ? `（透過）` : ''}
            </StyledFont>
          </Box>
          <Box>
            <StyledFont>生成数:{data.result.resultImages.length}</StyledFont>
          </Box>
        </StyledGridGap5>
      </StyedTaskContentContainer>
    </Box>
  );
});
