import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useError } from '@/hooks/global/useError';
import { useTaskTray } from '@/hooks/global/useTaskTray';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  deletedIds: string[];
};

export const useRequest = ({ deletedIds }: Props) => {
  const { featureData, updateFeatureDataArray, activateTargetStep } =
    useFeaturesContext({});
  const [requestSet, setRequestSet] = useState<FeatureBatch[]>([]);
  const [apiPostResponse, setApiPostResponse] = useState<Task | undefined>();
  const { postTasksV2 } = useBackendApi({});
  const { logger } = useError();
  const { setIsTaskTrayAnimation } = useTaskTray();
  const { setIsStopBatchGenerating } = useBatchOptionalContext();

  useEffect(() => {
    if (!featureData?.batch) return;
    if (featureData?.batch) {
      setRequestSet(
        featureData.batch.filter((batch) => !deletedIds.includes(batch.id)),
      );
    }
  }, [featureData?.batch, deletedIds]);

  const hasRequestSet = useMemo(
    () =>
      requestSet.length > 0 &&
      requestSet.some((set) => set.refImage.refImageSelectedId),
    [requestSet],
  );

  const handleRequestAll = useCallback(async () => {
    if (!hasRequestSet) return;
    await Promise.all(
      requestSet.map(async (set) => {
        try {
          await postTasksV2({
            displayName: set.setName,
            generationMode: 'generate_background_by_reuse_image',
            nSample: 1,
            originalImages: set.originalImages.map((image) => ({
              inputImageId: image.inputImageId,
              maskImageId: image.maskImageId,
            })),
            refImage: {
              inputImageId: set.refImage.refImageSelectedId,
            },
          });
        } catch (error) {
          logger({ error });
        }
      }),
    );

    activateTargetStep('batchUpload');
    setIsTaskTrayAnimation(true);
    setIsStopBatchGenerating(true);
    updateFeatureDataArray('batch', []);
  }, [
    hasRequestSet,
    requestSet,
    activateTargetStep,
    setIsTaskTrayAnimation,
    setIsStopBatchGenerating,
    updateFeatureDataArray,
    postTasksV2,
    logger,
  ]);

  return {
    apiPostResponse,
    hasRequestSet,
    handleRequestAll,
  };
};
